// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                resultsNavigation: {
                    faqUrl: "https://couchclues.com/#faq",
                    shopUrl: "https://couchclues.com/chapters/a-toxic-case/full/"
                },
                game: {
                    language: "Language",
                    languageEn: "English",
                    languageNl: "Nederlands",
                    continue: "CONTINUE!",
                    playNow: "Play Now",
                    seeResults: "See Results",
                    selectLanguage: "Please select the language",
                    go: "GO!",
                    results: "Results",
                    completed: "Completed:",
                    noPuzzles: "No puzzles available. Please visit the website to buy a game",
                    typeToAnswer: "Type answer here",
                    clickToTag: "Click to tag images",
                    clickToTagAnswer: "Click to tag answers",
                    clickToTagAnswerInOrder: "Tag answers in the correct order",
                    backToChat: "Back to chat",
                    chatWith: "Chatting with",
                    difficulty: "Difficulty",
                    copyLink: "Copy this link and share it with your friend:",
                    copyLinkLabel: "Copy invite link",
                    inviteFriend: "Invite a friend",
                    about: "About",
                    faq: "FAQ",
                    shop: "Shop",
                    waitForGo: "Please wait for the other player to press GO!",
                    shareAlinkWithPlayer: "Share a link to invite another player",
                    askPartnerToStart: "Ask your partner to start the game.",
                    guestPlayerNotReady: "Guest player is not ready yet.",
                    minimumPlayersText: "At least two players are required to play the game. {!isGuest ? ' Send the link to your friend and invite him to play ' : ''}",
                    copyInviteLinkLabel: "Copy invite link",
                    playerWaitingForPartner: "Wait for {{partnerName}} to enter the right solution. Maybe you can help?",
                    difficultyLabel: "Difficulty",
                    startButtonText: "Start the game",
                    playButtonText: "Play!",
                    shareLinkDialogTitle: "To start the game, share the link below with another player",
                    startGameDialogTitle: "Let's solve it together!",
                    yourNameLabel: "Your name",
                    getStartText: "Start the game",
                    errorGoHome: "Go home",
                    error: "Error",
                    notExists: "The game you’re looking for does not exist",
                    buyAnother: "You need to buy another chapter in order to play this game",
                    youDontHave: "You do not have this chapter",
                    getPuzzle: "Buy chapter",
                },
                puzzle: {
                    'translate.game.SIMPLE': 'Standard',
                    'translate.game.EASY': 'Challenging',
                    'translate.game.MEDIUM': 'Crazy Genius',
                    'translate.game.HARD': 'Expert',
                    'translate.game.toxic_case': 'A Toxic Case',
                    'translate.game.hint.explanation.label': "Explanation",
                    'translate.game.hint.hints.label': "Hints",
                    'translate.game.hint.hint1.label': "Hint 1",
                    'translate.game.hint.hint2.label': "Hint 2",
                    'translate.game.hint.solution.label': "Solution",
                    'translate.game.puzzle.timer.watch_video': "Watch the following video:",

                    'translate.game.hint.explanation_minus_points.label': "Explanation",
                    'translate.game.hint.hint1_minus_points.label': "Hint 1",
                    'translate.game.hint.hint2_minus_points.label': "Hint 2",

                    'translate.game.toxic_case.puzzle_name.intro1': "Intro 1",
                    'translate.game.toxic_case.puzzle_name.intro2': "Intro 2",
                    'translate.game.toxic_case.puzzle_name.deceased_persons': "Deceased people",
                    'translate.game.toxic_case.puzzle_name.differences': "Differences",
                    'translate.game.toxic_case.puzzle_name.drone': "Drone",
                    'translate.game.toxic_case.puzzle_name.enclose': "Enclose",
                    'translate.game.toxic_case.puzzle_name.escape': "Escape",
                    'translate.game.toxic_case.puzzle_name.open_the_door': "Open the door",
                    'translate.game.toxic_case.puzzle_name.password': "Password",
                    'translate.game.toxic_case.puzzle_name.poison': "Poison",
                    'translate.game.toxic_case.puzzle_name.tools_and_wires': "Tools and wires",
                    'translate.game.toxic_case.puzzle_name.who_did_it': "Who did it",


                    'translate.game.messages.answers.waiting': "OK, I know what to do. Let {{partnerName}} know that it is time to Go!",
                    'translate.game.messages.answers.incorrectWhenOpponentCorrect': "Wait! {{partnerName}} sent me something as well...",
                    'translate.game.messages.answers.bothIncorrect': "I tried your solutions but they didnt work.",
                    'translate.game.messages.answers.opponentCorrect': "Your solution didn't work, but the one {{partnerName}} sent me did!",
                    'translate.game.messages.answers.diffAnswersImRightButWaiting': "What you said seems correct, but the answer from {{partnerName}} is incorrect. Help {{partnerName}} out and tell me to go when they're ready.",

                    'translate.game.score.65': "Not bad! You scored better than 50% of players",
                    'translate.game.score.75': "Nice! You had a better score than 75% of all other players",
                    'translate.game.score.80': "Good score! You are in the top 20% of all players",
                    'translate.game.score.85': "Very good score! You are in the top 15% for this challenge",
                    'translate.game.score.90': "Great score! You are in the top 10% for this challenge.",
                    'translate.game.score.95': "Almost perfect! You are in the top 5% for this puzzle!",
                    'translate.game.score.100': "Congratulations! Less than 1% of players get a 100 for this puzzle.",
                    'translate.game.score.140': "Not bad! Better than 50% of players on 'challenging' level",
                    'translate.game.score.150': "Nice! You scored better than 55% of players on 'challenging' level",
                    'translate.game.score.160': "Well done! You are in the top 25% of players (that play 'challenging' difficulty)",
                    'translate.game.score.170': "Good score: You two in the top 20% (for 'challenging' difficulty)",
                    'translate.game.score.180': "Very good score! You are in the top 15% for this challenge (on 'challenging' level)",
                    'translate.game.score.190': "Great score! You are in the top 10% for this challenge (on 'challenging' level)",
                    'translate.game.score.195': "Almost perfect! You two are in the top 5% for this puzzle!",
                    'translate.game.score.200': "Amazing! Less than 1% of teams that play on challenging difficulty gets a 100 for this puzzle.",
                    'translate.game.score.241': "Impressive! Better than 50% of players on 'crazy' difficulty",
                    'translate.game.score.250': "Nice! You scored better then 55% of players on 'crazy' difficulty level",
                    'translate.game.score.260': "Well done! You are in the top 25% for this challenge on 'crazy' difficulty level",
                    'translate.game.score.270': "Good score: You two in the top 20% on 'crazy' difficulty level",
                    'translate.game.score.280': "Very good score! You are in the top 15% for this challenge on 'crazy' difficulty level",
                    'translate.game.score.290': "Great score! You are in the top 10% for this challenge on 'crazy' difficulty level",
                    'translate.game.score.295': "You are the crazy geniuses within the crazy geniuses group! You are in the top 5%",
                    'translate.game.score.300': "Wow! OK, there are 2 options. You are crazy geniuses, or you are cheating. But we don't want to tell crazy geniuses that they are cheating, so well done!",

                    'translate.1_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/INTRO-1square512.webp",
                    'translate.1_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/INTRO-1square512.webp",

                    'translate.2_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/INTRO-2square512.webp",
                    'translate.2_intro_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intmain5.webp",
                    'translate.2_intro_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intmain7.webp",
                    'translate.2_intro_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intguest1.webp",
                    'translate.2_intro_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intguest9.webp",
                    'translate.2_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/INTRO-2square512.webp",
                    'translate.2_intro_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intmain5.webp",
                    'translate.2_intro_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intmain7.webp",
                    'translate.2_intro_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intguest1.webp",
                    'translate.2_intro_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intguest9.webp",
                    'translate.2_intro_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/INTRO-2square512.webp",
                    'translate.2_intro_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intmain5.webp",
                    'translate.2_intro_medium.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intmain7.webp",
                    'translate.2_intro_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intguest1.webp",
                    'translate.2_intro_medium.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/intguest9.webp",

                    'translate.deceased_person_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2-square512.webp",
                    'translate.deceased_person_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2both.webp",
                    'translate.deceased_person_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2smainprescription.webp",
                    'translate.deceased_person_simple.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2smainoneheart.webp",
                    'translate.deceased_person_simple.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2smaintattooface.webp",
                    'translate.deceased_person_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2both.webp",
                    'translate.deceased_person_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2sguestglasses.webp",
                    'translate.deceased_person_simple.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2sguestearring.webp",
                    'translate.deceased_person_simple.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2sguestrahuljim.webp",
                    'translate.deceased_person_simple.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2sguest30years.webp",

                    'translate.deceased_person_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2-square512.webp",
                    'translate.deceased_person_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2both.webp",
                    'translate.deceased_person_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2mainage.webp",
                    'translate.deceased_person_easy.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2mainnotblonde.webp",
                    'translate.deceased_person_easy.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2mainnotfamily.webp",
                    'translate.deceased_person_easy.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2mainoneheart.webp",
                    'translate.deceased_person_easy.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2mainrahul.webp",
                    'translate.deceased_person_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2both.webp",
                    'translate.deceased_person_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2guestglasses.webp",
                    'translate.deceased_person_easy.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2guestheart.webp",
                    'translate.deceased_person_easy.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2guestmedicines.webp",
                    'translate.deceased_person_easy.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2guestnephew.webp",
                    'translate.deceased_person_easy.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2guesttattoos.webp",

                    'translate.deceased_person_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2-square512.webp",
                    'translate.deceased_person_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2both.webp",
                    'translate.deceased_person_medium.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cmaincar.webp",
                    'translate.deceased_person_medium.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cmaineldest.webp",
                    'translate.deceased_person_medium.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cmainnotheart.webp",
                    'translate.deceased_person_medium.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cmainthree.webp",
                    'translate.deceased_person_medium.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cmain5months.webp",
                    'translate.deceased_person_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2both.webp",
                    'translate.deceased_person_medium.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cguestage.webp",
                    'translate.deceased_person_medium.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cguestone.webp",
                    'translate.deceased_person_medium.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cguestoneglasses.webp",
                    'translate.deceased_person_medium.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cguesttattoos.webp",
                    'translate.deceased_person_medium.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/2cguestearrings5.webp",

                    'translate.drone_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4-square512.webp",
                    'translate.drone_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4main.webp",
                    'translate.drone_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4guest.webp",

                    'translate.drone_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4-square512.webp",
                    'translate.drone_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4main.webp",
                    'translate.drone_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4guest.webp",

                    'translate.drone_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4-square512.webp",
                    'translate.drone_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4cmain.webp",
                    'translate.drone_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/4cguest.webp",

                    'translate.enclose_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10-square512.webp",
                    'translate.enclose_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10sboth.webp",
                    'translate.enclose_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10sboth.webp",

                    'translate.enclose_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10-square512.webp",
                    'translate.enclose_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10main.webp",
                    'translate.enclose_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10guest.webp",

                    'translate.enclose_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10-square512.webp",
                    'translate.enclose_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10cmain.webp",
                    'translate.enclose_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/10cguest.webp",

                    'translate.open_the_door_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1-square512.webp",
                    'translate.open_the_door_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1both.webp",
                    'translate.open_the_door_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1main.webp",
                    'translate.open_the_door_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1both.webp",
                    'translate.open_the_door_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1guest.webp",

                    'translate.open_the_door_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1-square512.webp",
                    'translate.open_the_door_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1both.webp",
                    'translate.open_the_door_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1main.webp",
                    'translate.open_the_door_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1both.webp",
                    'translate.open_the_door_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1guest.webp",

                    'translate.open_the_door_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1-square512.webp",
                    'translate.open_the_door_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1both.webp",
                    'translate.open_the_door_medium.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1main.webp",
                    'translate.open_the_door_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1both.webp",
                    'translate.open_the_door_medium.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/1guest.webp",

                    'translate.password_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3-square512.webp",
                    'translate.password_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3smap.webp",
                    'translate.password_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3mainsymbols.webp",
                    'translate.password_simple.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3mainitems.webp",
                    'translate.password_simple.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3marker.webp",
                    'translate.password_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3smap.webp",
                    'translate.password_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3guestsymbols.webp",
                    'translate.password_simple.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3guestitems.webp",
                    'translate.password_simple.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3marker.webp",

                    'translate.password_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3-square512.webp",
                    'translate.password_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3smap.webp",
                    'translate.password_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3mainsymbols.webp",
                    'translate.password_easy.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3mainitems.webp",
                    'translate.password_easy.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3marker.webp",
                    'translate.password_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3smap.webp",
                    'translate.password_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3guestsymbols.webp",
                    'translate.password_easy.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3guestitems.webp",
                    'translate.password_easy.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3marker.webp",

                    'translate.password_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3-square512.webp",
                    'translate.password_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3cboth.webp",
                    'translate.password_medium.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3main.webp",
                    'translate.password_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3cboth.webp",
                    'translate.password_medium.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/3guest.webp",

                    'translate.poison_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5-square512.webp",
                    'translate.poison_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5both.webp",
                    'translate.poison_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5main.webp",
                    'translate.poison_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5both.webp",
                    'translate.poison_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5guest.webp",

                    'translate.poison_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5-square512.webp",
                    'translate.poison_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5both.webp",
                    'translate.poison_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5main.webp",
                    'translate.poison_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5both.webp",
                    'translate.poison_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5guest.webp",

                    'translate.poison_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5-square512.webp",
                    'translate.poison_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5both.webp",
                    'translate.poison_medium.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5cmain.webp",
                    'translate.poison_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5both.webp",
                    'translate.poison_medium.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/5cguest.webp",

                    'translate.who_did_it_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6-square512.webp",

                    'translate.who_did_it_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6-square512.webp",

                    'translate.who_did_it_medium.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6-square512.webp",
                    'translate.who_did_it_medium.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6cmainevan.webp",
                    'translate.who_did_it_medium.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6cmaingeorge.webp",
                    'translate.who_did_it_medium.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6cguestbruce.webp",
                    'translate.who_did_it_medium.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/6cguesthenry.webp",

                    'translate.1_intro_simple.player.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
                    'translate.1_intro_simple.player.hint.hint2': "The first digit is a 9. Make sure to share this with your partner. Communication is key in this game! Now check hint 2.",
                    'translate.1_intro_simple.player.hint.solution': "The solution is 936. <br> Your first hint revealed that the first digit is 9. The first hint for the other player was that the last digit was a 6. The second hint was the same for both of you: the second digit was 3.",
                    'translate.1_intro_simple.player.hint.hint3': "Did your partner tell you the last number? The second digit is a 3. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
                    'translate.1_intro_simple.guest.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click Hint 1.",
                    'translate.1_intro_simple.guest.hint.hint2': "The last digit is a 6. Make sure to share this with your partner. This game is all about talking to each other! Now check hint 2.",
                    'translate.1_intro_simple.guest.hint.solution': "The solution is 936. <br> Your first hint revealed that the last digit was a 6. The first hint for the other player was that the first digit was a 9. The second hint was the same for both of you: the second digit was 3.",
                    'translate.1_intro_simple.guest.hint.hint3': "Did your partner tell you the first number? The second digit is a 3. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
                    'translate.1_intro_simple.player.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play the game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11346414\" target=\"_blank\">Click this link to access Vimeo</a>, click 'Start Watching', select subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CouchClues' channel and find Playlist 'A Toxic Case'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_simple.player.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_simple.player.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_simple.player.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",
                    'translate.1_intro_simple.guest.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play the game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11346414\" target=\"_blank\">Click this link to access Vimeo</a>, click 'Start Watching', select subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CouchClues' channel and find Playlist 'A Toxic Case'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_simple.guest.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_simple.guest.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_simple.guest.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",

                    'translate.1_intro_easy.player.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
                    'translate.1_intro_easy.player.hint.hint2': "The first digit is a 9. Make sure to share this with your partner. Communication is key in this game! Now check hint 2.",
                    'translate.1_intro_easy.player.hint.solution': "The solution is 936. <br> Your first hint revealed that the first digit was a 9. The first hint for the other player was that the last digit was a 6. The second hint was the same for both of you: the second digit was a 3.",
                    'translate.1_intro_easy.player.hint.hint3': "Did your partner tell you the last number? The second digit is a 3. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge",
                    'translate.1_intro_easy.guest.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
                    'translate.1_intro_easy.guest.hint.hint2': "The last digit is a 6. Make sure to share this with your partner. This game is all about talking to each other! The only rule is that you never show your screen. Now check hint 2.",
                    'translate.1_intro_easy.guest.hint.solution': "The solution is 936. <br> Your first hint revealed that the last digit was a 6. The first hint for the other player was that the first digit was a 9. The second hint was the same for both of you: the second digit was a 3.",
                    'translate.1_intro_easy.guest.hint.hint3': "Did your partner tell you the first number? The second digit is a 3. You could now solve the puzzle, or check the solution to be sure. You can now solve the puzzle, or access the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
                    'translate.1_intro_easy.player.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play the game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Click this link to access Vimeo</a>, click 'Start Watching', select subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CouchClues' channel and find Playlist 'A Toxic Case'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_easy.player.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_easy.player.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_easy.player.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",
                    'translate.1_intro_easy.guest.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play the game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Click this link to access Vimeo</a>, click 'Start Watching', select subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CouchClues' channel and find Playlist 'A Toxic Case'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_easy.guest.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_easy.guest.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_easy.guest.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",

                    'translate.2_intro_simple.player.option1': "One",
                    'translate.2_intro_simple.player.option2': "Two",
                    'translate.2_intro_simple.player.option3': "Three",
                    'translate.2_intro_simple.player.option4': "Four",
                    'translate.2_intro_simple.player.option5': "Five",
                    'translate.2_intro_simple.player.option6': "Six",
                    'translate.2_intro_simple.player.option7': "Seven",
                    'translate.2_intro_simple.player.option8': "Eight",
                    'translate.2_intro_simple.player.option9': "Nine",
                    'translate.2_intro_simple.player.option10': "Zero",
                    'translate.2_intro_simple.guest.option1': "One",
                    'translate.2_intro_simple.guest.option2': "Two",
                    'translate.2_intro_simple.guest.option3': "Three",
                    'translate.2_intro_simple.guest.option4': "Four",
                    'translate.2_intro_simple.guest.option5': "Five",
                    'translate.2_intro_simple.guest.option6': "Six",
                    'translate.2_intro_simple.guest.option7': "Seven",
                    'translate.2_intro_simple.guest.option8': "Eight",
                    'translate.2_intro_simple.guest.option9': "Nine",
                    'translate.2_intro_simple.guest.option10': "Zero",

                    'translate.2_intro_simple.timer.title': "Intro 2 - A Toxic Case",
                    'translate.2_intro_simple.timer.subtitle': "The introduction puzzle will start in:",
                    'translate.2_intro_simple.player.hint.explanation': "Your goal is to find a 4 digit number. You will need to describe what you see on your phone and match it with what is on the television. Select the labels in the correct order and press submit.",
                    'translate.2_intro_simple.player.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_simple.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_simple.player.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
                    'translate.2_intro_simple.guest.hint.explanation': "Your goal is to find a 4 digit number. You will need to describe what you see on your phone and match it with what is on the television. Select the labels in the correct order and press submit.",
                    'translate.2_intro_simple.guest.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_simple.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_simple.guest.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
                    'translate.2_intro_simple.player.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try zooming in by tapping on them and pinching with your fingers.",
                    'translate.2_intro_simple.player.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_simple.player.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_simple.player.message3.title': "That's right! You will now continue to the real game. Have fun together!",
                    'translate.2_intro_simple.guest.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try zooming in by tapping on them and pinching with your fingers.",
                    'translate.2_intro_simple.guest.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_simple.guest.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_simple.guest.message3.title': "That's right! You will now continue to the real game. Have fun together!",

                    'translate.2_intro_easy.player.option1': "One",
                    'translate.2_intro_easy.player.option2': "Two",
                    'translate.2_intro_easy.player.option3': "Three",
                    'translate.2_intro_easy.player.option4': "Four",
                    'translate.2_intro_easy.player.option5': "Five",
                    'translate.2_intro_easy.player.option6': "Six",
                    'translate.2_intro_easy.player.option7': "Seven",
                    'translate.2_intro_easy.player.option8': "Eight",
                    'translate.2_intro_easy.player.option9': "Nine",
                    'translate.2_intro_easy.player.option10': "Zero",
                    'translate.2_intro_easy.guest.option1': "One",
                    'translate.2_intro_easy.guest.option2': "Two",
                    'translate.2_intro_easy.guest.option3': "Three",
                    'translate.2_intro_easy.guest.option4': "Four",
                    'translate.2_intro_easy.guest.option5': "Five",
                    'translate.2_intro_easy.guest.option6': "Six",
                    'translate.2_intro_easy.guest.option7': "Seven",
                    'translate.2_intro_easy.guest.option8': "Eight",
                    'translate.2_intro_easy.guest.option9': "Nine",
                    'translate.2_intro_easy.guest.option10': "Zero",

                    'translate.2_intro_easy.timer.title': "Intro 2 - A Toxic Case",
                    'translate.2_intro_easy.timer.subtitle': "The introduction puzzle will start in:",
                    'translate.2_intro_easy.player.hint.explanation': "Your goal is to find a 4 digit number. You will need to describe what you see on your phone and match it with what is on the television. Select the labels in the correct order and press submit.",
                    'translate.2_intro_easy.player.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_easy.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_easy.player.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
                    'translate.2_intro_easy.guest.hint.explanation': "Your goal is to find a 4 digit number. You will need to describe what you see on your phone and match it with what is on the television. Select the labels in the correct order and press submit.",
                    'translate.2_intro_easy.guest.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_easy.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_easy.guest.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
                    'translate.2_intro_easy.player.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try zooming in by tapping on them and pinching with your fingers.",
                    'translate.2_intro_easy.player.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_easy.player.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_easy.player.message3.title': "That's right! You will now continue to the real game. Have fun together!",
                    'translate.2_intro_easy.guest.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try zooming in by tapping on them and pinching with your fingers.",
                    'translate.2_intro_easy.guest.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_easy.guest.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_easy.guest.message3.title': "That's right! You will now continue to the real game. Have fun together!",

                    'translate.deceased_person_simple.timer.title': "2. Deceased persons - A Toxic Case",
                    'translate.deceased_person_simple.timer.subtitle': "The challenge will start in:",
                    'translate.deceased_person_simple.player.timer.message1': 'I went through more of his things. He noted that you can be certain 9 people were not poisoned. For the last three, remember the rule that only one has a heart condition.',
                    'translate.deceased_person_simple.player.timer.message2': 'On the back of the photo of Simon Carlson, it says that he was poisoned. Can you figure out who the other one is?',
                    'translate.deceased_person_simple.guest.timer.message1': 'I went through more of his things. He noted that you can be certain 9 people were not poisoned. For the last three, remember the rule that only one has a heart condition.',
                    'translate.deceased_person_simple.guest.timer.message2': 'On the back of the photo of Simon Carlson, it says that he was poisoned. Can you figure out who the other one is?',
                    'translate.deceased_person_simple.player.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduce which 2 of them were poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first on the age difference and the people that have a heart condition.",
                    'translate.deceased_person_simple.player.hint.hint1': "Focus first on eliminating the 9 people who were not poisoned. For the last three, remember the rule that only one has a heart condition.",
                    'translate.deceased_person_simple.player.hint.hint2': "One of the 2 poisoned people is Simon Carlson",
                    'translate.deceased_person_simple.player.hint.solution': "Simon and Sam. <br> People that have tattoos on their face, have glasses (an accident, so they were not poisoned) and Jim and Rahul (they don't have prescriptions) are excluded. Then, if you focus on 30 years apart, one of the two has to be Simon. Simon has a heart condition (earring), which means that the younger person does not have a heart condition (no earring). So it must be Sam.",
                    'translate.deceased_person_simple.guest.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduce which 2 of them were poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first on the age difference and the people that have a heart condition.",
                    'translate.deceased_person_simple.guest.hint.hint1': "Focus first on eliminating the 9 people who were not poisoned. For the last three, remember the rule that only one has a heart condition.",
                    'translate.deceased_person_simple.guest.hint.hint2': "One of the 2 poisoned people is Simon Carlson",
                    'translate.deceased_person_simple.guest.hint.solution': "Simon and Sam. <br> People that have tattoos on their face, have glasses (an accident, so they were not poisoned) and Jim and Rahul (they don't have prescriptions) are excluded. Then, if you focus on 30 years apart, one of the two has to be Simon. Simon has a heart condition (earring), which means that the younger person does not have a heart condition (no earring). So it must be Sam.",
                    'translate.deceased_person_simple.message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_simple.message2.title': "Let me know if you figure out which two were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_simple.message2.error': "I checked, but I think this contradicts some of his notes. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.deceased_person_simple.message3.title': "Yes, that makes sense. Now, let’s see out why he was investigating these two.",
                    'translate.deceased_person_simple.guest_message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_simple.guest_message2.title': "Let me know if you figure out which two were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_simple.guest_message2.error': "I checked, but I think this contradicts some of his notes.. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.deceased_person_simple.guest_message3.title': "Yes, that makes sense. Now, let’s see why he was investigating these two.",

                    'translate.deceased_person_easy.timer.title': "2. Deceased persons - A Toxic Case",
                    'translate.deceased_person_easy.timer.subtitle': "The challenge will start in:",
                    'translate.deceased_person_easy.player.timer.message1': 'I found more notes in his trash. It seems that Charlie Yang and Joe Lopez were not poisoned.',
                    'translate.deceased_person_easy.player.timer.message2': 'On the back of the photo of Simon Carlson it says that he was poisoned. Can you figure out who the other one is?',
                    'translate.deceased_person_easy.guest.timer.message1': 'I found more notes in his trash. It seems that Charlie Yang and Joe Lopez were not poisoned.',
                    'translate.deceased_person_easy.guest.timer.message2': 'On the back of the photo of Simon Carlson it says that he was poisoned. Can you figure out who the other one is?',
                    'translate.deceased_person_easy.player.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduce which 2 of them were poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first on the age difference and the people that have a heart condition.",
                    'translate.deceased_person_easy.player.hint.hint1': "Charlie Yang and Joe Lopez have not been poisoned.",
                    'translate.deceased_person_easy.player.hint.hint2': "One of the 2 poisoned people is Simon Carlson",
                    'translate.deceased_person_easy.player.hint.solution': "Simon and Sam. <br> People that are blonde, have glasses (an accident, so they were not poisoned) and Rahul (he does not have prescriptions) are excluded. Then, if you focus on 30 years apart, one of the two has to be Joe or Simon. If it is Joe, the younger person does not have tattoos (both cannot have tattoos) and has to have a heart condition. Only Charlie matches this, but he has a tattoo. This means that it cannot be Joe and one of the two must be Simon. This means that the younger person does not have a heart condition and cannot be family (not Jim). So it must be Sam.",
                    'translate.deceased_person_easy.guest.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduce which 2 of them were poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first on the age difference and the people that have a heart condition.",
                    'translate.deceased_person_easy.guest.hint.hint1': "Charlie Yang and Joe Lopez have not been poisoned.",
                    'translate.deceased_person_easy.guest.hint.hint2': "One of the 2 poisoned people is Simon Carlson",
                    'translate.deceased_person_easy.guest.hint.solution': "Simon and Sam. <br> People that are blonde, have glasses (an accident, so they were not poisoned) and Rahul (he does not have prescriptions) are excluded. Then, if you focus on 30 years apart, one of the two has to be Joe or Simon. If it is Joe, the younger person does not have tatoos (both cannot have tattoos) and has to have a heart condition. Only Charlie matches this, but he has a tattoo. This means that it cannot be Joe and one of the two must be Simon. This means that the younger person does not have a heart condition and cannot be family (not Jim). So it must be Sam.",
                    'translate.deceased_person_easy.message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_easy.message2.title': "Let me know if you figure out which two were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_easy.message2.error': "I checked, but I think this contradicts some of his notes. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.deceased_person_easy.message3.title': "Yes, that makes sense. Now, let’s see out why he was investigating these two.",
                    'translate.deceased_person_easy.guest_message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_easy.guest_message2.title': "Let me know if you figure out which two were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_easy.guest_message2.error': "I checked, but I think this contradicts some of his notes. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.deceased_person_easy.guest_message3.title': "Yes, that makes sense. Now, let’s see out why he was investigating these two.",

                    'translate.deceased_person_medium.player.timer.message1': 'I think I found something. Maybe you have already figured this out, but if you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saterday and the second thing he does on Sunday are all the same.',
                    'translate.deceased_person_medium.player.timer.message2': 'Check the clock times. He is using the time on the clock in the code for that day',
                    'translate.deceased_person_medium.guest.timer.message1': 'I think I found something. Maybe you have already figured this out, but if you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saterday and the second thing he does on Sunday are all the same.',
                    'translate.deceased_person_medium.guest.timer.message2': 'Check the clock times. He is using the time on the clock in the code for that day',
                    'translate.deceased_person_medium.player.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduct which 2 of them have been poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for.",
                    'translate.deceased_person_medium.player.hint.hint1': "Focus first on finding the eldest three people and the ones with tattoos. Then you have 6 people that took prescription medicines. How many of those 6 people have glasses? And what does this mean for the other people that have glasses?",
                    'translate.deceased_person_medium.player.hint.hint2': "Anna, Simon and Charlie have earrings and took prescription medicines. The people that took prescription medicines but are not one of the three eldest were not poisoned.",
                    'translate.deceased_person_medium.player.hint.solution': "Simon and Sam. <br> The three eldest and the people with tattoos took prescription medicines. These are Simon, Joe, Rahul, Justin, Charlie, Matthew. Matthew has glasses, so all other people with glasses had a known heart condition. This leaves 3 people that had earrings, don't have a heart condition and took prescription medicines; Simon, Charlie and Anna. This means Jim is the only person with blue eyes who didn't take prescription medicines. He died in an accident. The people we are looking for are 30 years apart, they did not have a heart condition and only one of them used prescription medicines (the eldest of the two). This means that younger person must be Sam. One of the two had earrings, so the other one must be Simon.",
                    'translate.deceased_person_medium.guest.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduct which 2 of them have been poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for.",
                    'translate.deceased_person_medium.guest.hint.hint1': "Focus first on finding the eldest three people and the ones with tattoos. Then you have 6 people that took prescription medicines. How many of those 6 people have glasses? And what does this mean for the other people that have glasses?",
                    'translate.deceased_person_medium.guest.hint.hint2': "Anna, Simon and Charlie have earrings and took prescription medicines. The people that took prescription medicines but are not one of the three eldest were not poisoned.",
                    'translate.deceased_person_medium.guest.hint.solution': "Simon and Sam. <br> The three eldest and the people with tattoos took prescription medicines. These are Simon, Joe, Rahul, Justin, Charlie, Matthew. Matthew has glasses, so all other people with glasses had a known heart condition. This leaves 3 people that had earrings, don't have a heart condition and took prescription medicines; Simon, Charlie and Anna. This means Jim is the only person with blue eyes who didn't take prescription medicines. He died in an accident. The people we are looking for are 30 years apart, they did not have a heart condition and only one of them used prescription medicines (the eldest of the two). This means that younger person must be Sam. One of the two had earrings, so the other one must be Simon.",
                    'translate.deceased_person_medium.message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_medium.message2.title': "Let me know if you can find out which 2 people were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_medium.message2.error': "I checked it, but I think this contradicts with some of his notes. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.deceased_person_medium.message3.title': "Yes, that makes sense. Now lets find out why he was looking into these two.",
                    'translate.deceased_person_medium.guest_message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_medium.guest_message2.title': "Let me know if you can find out which 2 people were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_medium.guest_message2.error': "I checked it, but I think this contradicts with some of his notes. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.deceased_person_medium.guest_message3.title': "Yes, that makes sense. Now lets find out why he was looking into these two.",

                    'translate.differences_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/8-square512.webp",
                    'translate.differences_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/8mainletter.webp",
                    'translate.differences_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/8mainimage.webp",
                    'translate.differences_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/8guestletter.webp",
                    'translate.differences_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/8guestimage.webp",
                    'translate.differences_simple.player.hint.explanation': "Your goal is to find a hidden message. You have to describe the items to each other and note the zones in which one item is missing. If you do this, you get a selection of letters. The order of the letters is shown by the video. You have to figure out what connects these letters with the images on the video.",
                    'translate.differences_simple.player.hint.hint1': "Each player is missing 4 items. Try to find them all, before looking at the video to determine the order of the letters.",
                    'translate.differences_simple.player.hint.hint2': "The images on the video correspond with a zone where an item is missing. The video starts with a tent, which represents the letter T. The video ends with a net, which represents the letter N.",
                    'translate.differences_simple.player.hint.solution': "TIMS BARN. On Linda's message there is a hint that she will miss them always, miss is underlined. If you look at the images you see that each image has 3 items missing in zones that correspond with the letters A T S N and R I B M. To find the order of the letters, you need to like at the video. Every image relates to a word that starts with one of these letters: Tent, Ice, Mud, Spider, Balloon, Ant, River and Nest. The order is TIMSBARN.",
                    'translate.differences_simple.guest.hint.explanation': "Your goal is to find a hidden message. You have to describe the items to each other and note the zones in which one item is missing. If you do this, you get a selection of letters. The order of the letters is shown by the video. You have to figure out what connects these letters with the images on the video.",
                    'translate.differences_simple.guest.hint.hint1': "Each player is missing 4 items. Try to find them all, before looking at the video to determine the order of the letters.",
                    'translate.differences_simple.guest.hint.hint2': "The images on the video correspond with a zone where an item is missing. The video starts with a tent, which represents the letter T. The video ends with a net, which represents the letter N.",
                    'translate.differences_simple.guest.hint.solution': "TIMS BARN. On Linda's message there is a hint that she will miss them always, miss is underlined. If you look at the images you see that each image has 3 items missing in zones that correspond with the letters A T S N and R I B M. To find the order of the letters, you need to like at the video. Every image relates to a word that starts with one of these letters: Tent, Ice, Mud, Spider, Balloon, Ant, River and Nest. The order is TIMSBARN.",
                    'translate.differences_simple.message1.title': "She left a letter with some images to her parents, as well as a video for both of them. ",
                    'translate.differences_simple.message2.error': "I don't understand what this means. Can you check again, using the TAG ANSWER link above? It must make some sense...",
                    'translate.differences_simple.message2.title': "It must be a message of some sort. Analyse the images to see if you notice anything and see if you can connect that to the video.",
                    'translate.differences_simple.message3.title': "Tims barn. Well done. I'll try to find out where this barn is located.",
                    'translate.differences_simple.guest_message1.title': "She left a letter with some images to her parents, as well as a video for both of them. ",
                    'translate.differences_simple.guest_message2.title': "It must be a message of some sort. Analyse the images to see if you notice anything and see if you can connect that to the video.",
                    'translate.differences_simple.guest_message2.error': "I don't understand what this means. Can you check again, using the TAG ANSWER link above? It must make some sense...",
                    'translate.differences_simple.guest_message3.title': "Tims barn. Well done. I'll try to find out where this barn is located.",

                    'translate.drone_simple.timer.title': "4. Drone - A Toxic Case",
                    'translate.drone_simple.timer.subtitle': "The challenge will start in:",
                    'translate.drone_simple.player.timer.message1': 'I focused on the roads and identified the first two zones. The drone first flies over zone Y and then over zone L.',
                    'translate.drone_simple.player.timer.message2': 'There are some zones where the drone does not fly over. These are zones C, E, P, Q, T and V. I still need to figure out the correct order for the other zones before I can move.',
                    'translate.drone_simple.guest.timer.message1': 'I focused on the roads and identified the first two zones. The drone first flies over zone Y and then over zone L.',
                    'translate.drone_simple.guest.timer.message2': 'There are some zones where the drone does not fly over. These are zones C, E, P, Q, T and V. I still need to figure out the correct order for the other zones before I can move.',
                    'translate.drone_simple.player.hint.explanation': "The drone flies in 6 zones. Each player sees the same zones, but different buildings. Figure out the correct order by analyzing the shape of the buildings.",
                    'translate.drone_simple.player.hint.hint1': "The first zone is Y and the second zone is L.",
                    'translate.drone_simple.player.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
                    'translate.drone_simple.player.hint.solution': "The correct order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the center, with grass to the right. The building on the right seems to be missing a block in the top right and bottom left of the building. You have this building on your map, your partner has the long one in the center. The second zone has a slanted road, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a shape that is on your map. The fourth zone you see a very big building that is on your map. The fifth zone shows three buildings decreasing in size from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",
                    'translate.drone_simple.guest.hint.explanation': "The drone flies in 6 zones. Each player sees the same zones, but different buildings. Figure out the correct order by analyzing the shape of the buildings.",
                    'translate.drone_simple.guest.hint.hint1': "The first zone is Y and the second zone is L.",
                    'translate.drone_simple.guest.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
                    'translate.drone_simple.guest.hint.solution': "The correct order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the center, with grass to the right. The building on the right seems to be missing a block in the top right and bottom left of the building. The other player has this building on their map, you have the long one in the center. The second zone has a slanted road, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a shape that is on the map of your partner. The fourth zone you see a very big building that is on the map of your partner. The fifth zone shows three buildings decreasing in size from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",
                    'translate.drone_simple.message1.title': "This area is patrolled by a drone. I've managed to hack the footage, but I still don't know how to get in undetected. John made two different sketches of the area. I will send you a sketch of the office buildings and {{partnerName}} a sketch of the research facilities in the area. Focus on both the buildings and the roads.",
                    'translate.drone_simple.message2.title': "The drone flies through 6 zones. Let me know which zones and in what order, so I can find a way to evade the drone.",
                    'translate.drone_simple.message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again. Use the TAG ANSWER link above and let me know.",
                    'translate.drone_simple.message3.title': "Thanks. I'm going to make a run for it.",
                    'translate.drone_simple.guest_message1.title': "This area is patrolled by a drone. I've managed to hack the footage, but I still don't know how to get in undetected. John made two different sketches of the area. I will send you a sketch of the research facilities and {{partnerName}} a sketch of the office buildings in the area. Focus on both the buildings and the roads.",
                    'translate.drone_simple.guest_message2.title': "The drone flies through 6 zones. Let me know which zones and in what order, so I can find a way to evade the drone.",
                    'translate.drone_simple.guest_message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check it again. Use the TAG ANSWER link above and let me know.",
                    'translate.drone_simple.guest_message3.title': "Thanks. I'm going to make a run for it.",

                    'translate.drone_easy.timer.title': "4. Drone - A Toxic Case",
                    'translate.drone_easy.timer.subtitle': "The challenge will start in:",
                    'translate.drone_easy.player.timer.message1': 'I focused on the roads and identified the first two zones. The drone first flies over zone Y and then over zone L.',
                    'translate.drone_easy.player.timer.message2': 'There are some zones where the drone does not fly over. These are zones C, E, P, Q, T and V. I still need to figure out the correct order for the other zones before I can move.',
                    'translate.drone_easy.guest.timer.message1': 'I focused on the roads and identified the first two zones. The drone first flies over zone Y and then over zone L.',
                    'translate.drone_easy.guest.timer.message2': 'There are some zones where the drone does not fly over. These are zones C, E, P, Q, T and V. I still need to figure out the correct order for the other zones before I can move.',
                    'translate.drone_easy.player.hint.explanation': "The drone flies in 6 zones. Each player sees the same zones, but different buildings. Figure out the correct order by analyzing the roads and the shape of the buildings.",
                    'translate.drone_easy.player.hint.hint1': "The first zone is Y and the second zone is L.",
                    'translate.drone_easy.player.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
                    'translate.drone_easy.player.hint.solution': "The correct order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the center, with grass to the right. The building on the right seems to be missing a block in the top right and bottom left of the building. You have this building on your map, your partner has the long one in the center. The second zone has a slanted road, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a shape that is on your map. The fourth zone you see a very big building that is on your map. The fifth zone shows three buildings decreasing in size from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",
                    'translate.drone_easy.guest.hint.explanation': "The drone flies in 6 zones. Each player sees the same zones, but different buildings. Figure out the correct order by analyzing the roads and the shape of the buildings.",
                    'translate.drone_easy.guest.hint.hint1': "The first zone is Y and the second zone is L.",
                    'translate.drone_easy.guest.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
                    'translate.drone_easy.guest.hint.solution': "The correct order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the center, with grass to the right. The building on the right seems to be missing a block in the top right and bottom left of the building. The other player has this building on their map, you have the long one in the center. The second zone has a slanted road, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a shape that is on the map of your partner. The fourth zone you see a very big building that is on the map of your partner. The fifth zone shows three buildings decreasing in size from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",
                    'translate.drone_easy.message1.title': "This area is patrolled by a drone. I've managed to hack the footage, but I still don't know how to get in undetected.  John made a sketch of the office buildings in the area. I will send it to you.",
                    'translate.drone_easy.message2.title': "Let me know the order of the zones based on the drone footage, so I can find a way to evade the drone.",
                    'translate.drone_easy.message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again. Use the TAG ANSWER link above and let me know.",
                    'translate.drone_easy.message3.title': "Thanks. I'm going to make a run for it.",
                    'translate.drone_easy.guest_message1.title': "This area is patrolled by a drone. I've managed to hack the footage, but I still don't know how to get in undetected. John made a sketch of the research facilities in the area. I will send it to you.",
                    'translate.drone_easy.guest_message2.title': "Let me know the order of the zones based on the drone footage, so I can find a way to evade the drone.",
                    'translate.drone_easy.guest_message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check it again. Use the TAG ANSWER link above and let me know.",
                    'translate.drone_easy.guest_message3.title': "Thanks. I'm going to make a run for it.",

                    'translate.drone_medium.player.hint.explanation': "The drone flies in 6 zones. Find the right order by looking at the shape of the buildings.",
                    'translate.drone_medium.player.hint.hint1': "The first zone is Y and the second zone is L.",
                    'translate.drone_medium.player.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
                    'translate.drone_medium.player.hint.solution': "The order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the middle, with grass to the right. The right building looks like it misses a block in the top right and bottom left of the building. You have this building on your map, your partner has the long one in the middle. The second zone has buildings that are placed diagonally, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a shape that is on your map. The fourth zone you see a very big building that is on your map. The fifth zone shows three buildings descending from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",
                    'translate.drone_medium.guest.hint.explanation': "The drone flies in 6 zones. Find the right order by looking at the shape of the buildings.",
                    'translate.drone_medium.guest.hint.hint1': "The first zone is Y and the second zone is L.",
                    'translate.drone_medium.guest.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
                    'translate.drone_medium.guest.hint.solution': "The order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the middle, with grass to the right. The right building looks like it misses a block in the top right and bottom left of the building. The other player has this building on their map, you have the long one in the middle. The second zone has buildings that are placed diagonally, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a shape that is on the map of your partner.The fourth zone you see a very big building that is on the map of your partner. The fifth zone shows three buildings descending from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",
                    'translate.drone_medium.message1.title': "This area is patrolled by drones. I need to get in undetected. Look at the drone footage.  John made a sketch of the office buildings in the area. I will send it to you.",
                    'translate.drone_medium.message2.title': "Let me know the order of the zones that the drone sees, so I can find a way to sneak past them.",
                    'translate.drone_medium.message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again. Use the TAG ANSWER link above and let me know.",
                    'translate.drone_medium.message3.title': "Thanks. I'm going to make a run for it.",
                    'translate.drone_medium.guest_message1.title': "This area is patrolled by drones. I need to get in undetected. Look at the drone footage.  John made a sketch of the research facilities in the area. I will send it to you.",
                    'translate.drone_medium.guest_message2.title': "Let me know the order of the zones that the drone sees, so I can find a way to sneak past them.",
                    'translate.drone_medium.guest_message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again. Use the TAG ANSWER link above and let me know.",
                    'translate.drone_medium.guest_message3.title': "Thanks. I'm going to make a run for it.",

                    'translate.enclose_simple.timer.title': "7. Enclose - A Toxic Case",
                    'translate.enclose_simple.timer.subtitle': "The challenge will start in:",
                    'translate.enclose_simple.player.timer.message1': 'I have counted how many times I heard a door open and close. In total we need to identify 8 rooms, including her starting point 270.',
                    'translate.enclose_simple.player.timer.message2': 'Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. Did you spot her on camera in room 201?',
                    'translate.enclose_simple.guest.timer.message1': 'I have counted how many times I heard a door open and close. In total we need to identify 8 rooms, including her starting point 270.',
                    'translate.enclose_simple.guest.timer.message2': 'Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. Did you spot her on camera in room 201?',
                    'translate.enclose_simple.player.hint.explanation': "Your goal is to figure out the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
                    'translate.enclose_simple.player.hint.hint1': "Justin, the researcher, is in the toilet near room 281. He only heard something in the room next to him once Linda passed Steve. In total we need to identify 8 rooms, including her starting point 270.",
                    'translate.enclose_simple.player.hint.hint2': "Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. She is not seen on camera in room 201, so she must have gone through 217 to the hallway.",
                    'translate.enclose_simple.player.hint.solution': "270 - 228 - 266 -  204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. This means that Justin must be in the toilet next to 281, because if he was in another toilet, he would have heard something earlier in the room next to him (including when she was in room 270). From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that she hid in the closet on the top right of the map. This means that Linda went through room 217. She cannot have gone through 201, because she wasn't seen on video there.",
                    'translate.enclose_simple.guest.hint.explanation': "Your goal is to figure out the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
                    'translate.enclose_simple.guest.hint.hint1': "Justin, the researcher, is in the toilet near room 281. He only heard something in the room next to him once Linda passed Steve. In total we need to identify 8 rooms, including her starting point 270.",
                    'translate.enclose_simple.guest.hint.hint2': "Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. She is not seen on camera in room 201, so she must have gone through 217 to the hallway.",
                    'translate.enclose_simple.guest.hint.solution': "270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. This means that Justin must be in the toilet next to 281, because if he was in another toilet, he would have heard something earlier in the room next to him (including when she was in room 270). From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that she hid in the closet on the top right of the map. This means that Linda went through room 217. She cannot have gone through 201, because she wasn't seen on video there.",
                    'translate.enclose_simple.message1.title': "We need to deactivate the bombs before she can set them off. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of the researchers. I have told them all I know.",
                    'translate.enclose_simple.message2.title': "Can you figure out the route that Linda takes? She starts in room 270. Try to identify the rooms we have got on camera and pay close attention to what Justin and Jennifer are saying",
                    'translate.enclose_simple.message2.error': "I'm not sure that this is right. We have only one shot, can you check again? Use the TAG ANSWER link above and let me know the route she takes.",
                    'translate.enclose_simple.message3.title': "OK, let's do this. When Linda enters 204, Justin can go to 266 to deactive the bomb. I will wait for as long as I can and take out the bomb in 270. Play the next video '8. The End' before pressing results.",
                    'translate.enclose_simple.guest_message1.title': "We need to deactivate the bombs before she can set them off. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of the researchers. I have told them all I know.",
                    'translate.enclose_simple.guest_message2.title': "Can you figure out the route that Linda takes? She starts in room 270. Try to identify the rooms we have got on camera and pay close attention to what Justin and Jennifer are saying.",
                    'translate.enclose_simple.guest_message2.error': "I'm not sure that this is right. We have only one shot, can you check again? Use the TAG ANSWER link above and let me know the route she takes.",
                    'translate.enclose_simple.guest_message3.title': "OK, let's do this. When Linda enters 204, Justin can go to 266 to deactive the bomb. I will wait for as long as I can and take out the bomb in 270. Play the next video '8. The End' before pressing results",

                    'translate.enclose_easy.timer.title': "7. Enclose - A Toxic Case",
                    'translate.enclose_easy.timer.subtitle': "The challenge will start in:",
                    'translate.enclose_easy.player.timer.message1': 'I have counted how many times I heard a door open and close. In total we need to identify 8 rooms, including her starting point 270. Find the rooms where we have camera access by focussing on where the doors are.',
                    'translate.enclose_easy.player.timer.message2': 'She is never seen on camera in room 201. Jennifer is in the closet in the top right corner and did not hear anything, not even in the rooms next to her.',
                    'translate.enclose_easy.guest.timer.message1': 'I have counted how many times I heard a door open and close In total we need to identify 8 rooms, including her starting point 270. Find the rooms where we have camera access by focussing on where the doors are.',
                    'translate.enclose_easy.guest.timer.message2': 'She is never seen on camera in room 201.  That room cannot be part of her route. Jennifer is in the closet in the top right corner and did not hear anything, not even in the rooms next to her.',
                    'translate.enclose_easy.player.hint.explanation': "Your goal is to figure out the route that Linda is taking. Focus first on finding out where Jennifer and Justin (the researcher) are on the map. When do they hear something and when not? Do they hear something in the room or rooms next to them? What does this mean for where they are located?",
                    'translate.enclose_easy.player.hint.hint1': "In total we need to identify 8 rooms, including her starting point 270. Find the rooms where we have camera access by focussing on where the doors are. In which room is she never seen on camera?",
                    'translate.enclose_easy.player.hint.hint2': "We have access to the camera in room 201 but she is never seen there. Jennifer is in the closet in the top right corner and did not hear anything, not even in the rooms next to her. Justin is in the toilet next to room 281.",
                    'translate.enclose_easy.player.hint.solution': "270 - 228 - 266 -  204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. This means that Justin must be in the toilet next to 281, because if he was in another toilet, he would have heard something earlier in the room next to him (including when she was in room 270). From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that she hid in the closet on the top right of the map. This means that Linda went through room 217. She cannot have gone through 201, because she wasn't seen on video there.",
                    'translate.enclose_easy.guest.hint.explanation': "Your goal is to figure out the route that Linda is taking. Focus first on finding out where Jennifer and Justin (the researcher) are on the map. When do they hear something and when not? Do they hear something in the room or rooms next to them? What does this mean for where they are located?",
                    'translate.enclose_easy.guest.hint.hint1': "In total we need to identify 8 rooms, including her starting point 270. Find the rooms where we have camera access by focussing on where the doors are. In which room is she never seen on camera?",
                    'translate.enclose_easy.guest.hint.hint2': "We have access to the camera in room 201 but she is never seen there. Jennifer is in the closet in the top right corner and did not hear anything, not even in the rooms next to her.Justin is in the toilet next to room 281.",
                    'translate.enclose_easy.guest.hint.solution': "270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. This means that Justin must be in the toilet next to 281, because if he was in another toilet, he would have heard something earlier in the room next to him (including when she was in room 270). From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that she hid in the closet on the top right of the map. This means that Linda went through room 217. She cannot have gone through 201, because she wasn't seen on video there.",
                    'translate.enclose_easy.message1.title': "We need to deactivate the bombs before she can set them off. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of the researchers. I have told them all I know.",
                    'translate.enclose_easy.message2.title': "I managed to get access to only a few of the security camera's. Please check which rooms we can see on camera, so we know if she moves through those rooms. We need to figure out which route she takes, if I counted correctly I think she goes through 8 rooms in total.",
                    'translate.enclose_easy.message2.error': "I'm not sure that this is right. We have only one shot, can you check again? I think she starts in room 270. Use the TAG ANSWER link above and let me know the route she takes.",
                    'translate.enclose_easy.message3.title': "OK, let's do this. When Linda enters 204, Justin can go to 266 to deactive the bomb. I will wait for as long as I can and take out the bomb in 270. Play the next video '8. The End' before pressing results.",
                    'translate.enclose_easy.guest_message1.title': "We need to deactivate the bombs before she can set them off. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of the researchers. I have told them all I know.",
                    'translate.enclose_easy.guest_message2.title': "I managed to get access to only a few of the security camera's. Please check which rooms we can see on camera, so we know if she moves through those rooms. We need to figure out which route she takes, if I counted correctly I think she goes through 8 rooms in total.",
                    'translate.enclose_easy.guest_message2.error': "I'm not sure that this is right. We have only one shot, can you check again? I think she starts in room 270. Use the TAG ANSWER link above and let me know the route she takes.",
                    'translate.enclose_easy.guest_message3.title': "OK, let's do this. When Linda enters 204, Justin can go to 266 to deactive the bomb. I will wait for as long as I can and take out the bomb in 270.. Play the next video '8. The End' before pressing results",


                    'translate.enclose_medium.player.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on finding out where Jennifer and Justin (the researcher) are on the map.",
                    'translate.enclose_medium.player.hint.hint1': "Justin, the researcher, is in the toilet near room 281. He only heard something in the room next to him once Linda passed Steve. Jennifer didn't hear anything at all. Where would she be located?",
                    'translate.enclose_medium.player.hint.hint2': "Jennifer is located in the closet in the top right corner. She says she didn't hear anything, not even in the rooms next to her. This means that Linda went through room 217 and from there to the hallway with motion detector.",
                    'translate.enclose_medium.player.hint.solution': "270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. This means that Justin must be in the toilet next to 281, because if he was in another toilet, he would have heard something earlier in the room next to him (including when she was in room 270). From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that she hid in the closet on the top right of the map. This means that Linda went through room 217. She cannot have gone through 201, because she wasn't seen on video there.",
                    'translate.enclose_medium.guest.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on finding out where Jennifer and Justin (the researcher) are on the map.",
                    'translate.enclose_medium.guest.hint.hint1': "Justin, the researcher, is in the toilet near room 281. He only heard something in the room next to him once Linda passed Steve. Jennifer didn't hear anything at all. Where would she be located?",
                    'translate.enclose_medium.guest.hint.hint2': "Jennifer is located in the closet in the top right corner. She says she didn't hear anything, not even in the rooms next to her. This means that Linda went through room 217 and from there to the hallway with motion detector.",
                    'translate.enclose_medium.guest.hint.solution': "270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. This means that Justin must be in the toilet next to 281, because if he was in another toilet, he would have heard something earlier in the room next to him (including when she was in room 270). From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that she hid in the closet on the top right of the map. This means that Linda went through room 217. She cannot have gone through 201, because she wasn't seen on video there.",
                    'translate.enclose_medium.message1.title': "We have to stop the bombs before she can detonate them. This means we have to stop them at the same time, without her seeing one of us in the meantime. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of her researchers.",
                    'translate.enclose_medium.message2.title': "We need to find out the route that Linda takes so we know when to move.",
                    'translate.enclose_medium.message2.error': "If we do it like that she will see one of us before we have a chance to defuse the bomb. We need another plan. Use the TAG ANSWER link above and let me know the route she takes.",
                    'translate.enclose_medium.message3.title': "We have one shot. Let's do this. Play the next video '8. The End' before pressing results.",
                    'translate.enclose_medium.guest_message1.title': "We have to stop the bombs before she can detonate them. This means we have to stop them at the same time, without her seeing one of us in the meantime. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of the researchers.",
                    'translate.enclose_medium.guest_message2.title': "We need to find out the route that Linda takes so we know when to move.",
                    'translate.enclose_medium.guest_message2.error': "If we do it like that she will see one of us before we have a chance to defuse the bomb. We need another plan. Use the TAG ANSWER link above and let me know the route she takes.",
                    'translate.enclose_medium.guest_message3.title': "We have one shot. Let's do this. Play the next video '8. The End' before pressing results.",

                    'translate.escape_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/7-square512.webp",
                    'translate.escape_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/7main.webp",
                    'translate.escape_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/7guest.webp",
                    'translate.escape_simple.player.hint.explanation': "You both see the same floorplan, in which there are 3 distinct floors. If he moves between floors you should be able to hear it. Tell each other which floor you think he is in and if he is moving left, right, down, or up on your map. If you see him trying to access a closed door on your map, let your partner know the number as well.",
                    'translate.escape_simple.player.hint.hint1': "He starts in the hallway and tries to open door 318. It is locked. There are 8 doors locked in total.",
                    'translate.escape_simple.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_simple.player.hint.solution': "717, 746, 822, 853, 861, 895, 952, 965. John starts in the largest hallway on the 8th floor and tries to open the last door on the left of the hallway: 895. Then he moves through the door on the right side of the hallway, to the 9th floor. Here he passes two doors and tries the door at the end: 965. Then he goes back to the 8th floor now walking in the oppossite direction of how he started. He passes one door on the left and tries to open the second door on his left: 822. Then he walks back and tries the door he passed before: 861. He now moves through another door, down to the 7th floor. You see him try the door on his right and see two other yellow doors, then he walks back to the 8th floor and tries another door on his left: 853. After that you see him passing 895 and go to the 9th floor. This time he opens the second door to his left: 952. Then he goes back to the 8th floor, from there to the 7th floor and this time he tries the left one of the yellow doors: 717.",
                    'translate.escape_simple.guest.hint.explanation': "You both see the same floorplan, in which there are 3 distinct floors. If he moves between floors you should be able to hear it. Tell each other which floor you think he is in and if he is moving left, right, down, or up on your map. If you see him trying to access a closed door on your map, let your partner know the number as well.",
                    'translate.escape_simple.guest.hint.hint1': "He starts in the hallway and tries to open door 318. It is locked. There are 8 doors locked in total.",
                    'translate.escape_simple.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_simple.guest.hint.solution': "717, 746, 822, 853, 861, 895, 952, 965. John starts in the largest hallway on the 8th floor and tries to open the last door on the left of the hallway: 895. Then he moves through the door on the right side of the hallway, to the 9th floor. Here he passes two doors and tries the door at the end: 965. Then he goes back to the 8th floor now walking in the oppossite direction of how he started. He passes one door on the left and tries to open the second door on his left: 822. Then he walks back and tries the door he passed before: 861. He now moves through another door, down to the 7th floor. You see him try the door on his right and see two other yellow doors, then he walks back to the 8th floor and tries another door on his left: 853. After that you see him passing 895 and go to the 9th floor. This time he opens the second door to his left: 952. Then he goes back to the 8th floor, from there to the 7th floor and this time he tries the left one of the yellow doors: 717.",
                    'translate.escape_simple.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_simple.message2.title': "If we knew which rooms are locked, I could narrow down where she is.",
                    'translate.escape_simple.message2.error': "Were these the right doors? Please use the TAG ANSWER link above and try again.",
                    'translate.escape_simple.message3.title': "She must have been in one of those other four rooms. I'll try to find her.",
                    'translate.escape_simple.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_simple.guest_message2.title': "If we knew which rooms are locked, I could narrow down where she is.",
                    'translate.escape_simple.guest_message2.error': "Were these the right doors? Please use the TAG ANSWER link above and try again.",
                    'translate.escape_simple.guest_message3.title': "She must have been in one of those other four rooms. I'll try to find her.",

                    'translate.open_the_door_simple.timer.title': "1. Open the Door - A Toxic Case",
                    'translate.open_the_door_simple.timer.subtitle': "The challenge will start in:",
                    'translate.open_the_door_simple.player.timer.message1': 'You may have already noticed this, but on the footage I see him doing the same four things each day. Is there a connection between the order he does things and the code?',
                    'translate.open_the_door_simple.player.timer.message2': 'I think I found something. If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
                    'translate.open_the_door_simple.player.timer.message3': 'Check the clock times. He is using the time on the clock as part of the code for that day.',
                    'translate.open_the_door_simple.guest.timer.message1': 'You may have already noticed this, but on the footage I see him doing the same four things each day. Is there a connection between the order he does things and the code?',
                    'translate.open_the_door_simple.guest.timer.message2': 'I think I found something. If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
                    'translate.open_the_door_simple.guest.timer.message3': 'Check the clock times. He is using the time on the clock as part of the code for that day.',
                    'translate.open_the_door_simple.player.hint.explanation': "You need to enter a 4-digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage from those days. Each day, John does four things. Each number he uses on a day is connected to one of the things that he is doing. You have to find out how this works and what the code for today would be.",
                    'translate.open_the_door_simple.player.hint.hint1': "The numbers 1,4 and 9 are used each day. Only the other number differs. If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.",
                    'translate.open_the_door_simple.player.hint.hint2': "The time on the clock can be used to determine one of the numbers of the code for that day. For example, if the time on one of the days was 8 o'clock, one of the numbers will be an 8.",
                    'translate.open_the_door_simple.player.hint.solution': "1459 is the code for today. <br> Each day, John does four things in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. So Friday the last thing he did was looking at the clock, the time was 8. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5.",
                    'translate.open_the_door_simple.guest.hint.explanation': "You need to enter a 4-digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage from those days. Each day, John does four things. Do you see a pattern? And can you connect this to the door codes?",
                    'translate.open_the_door_simple.guest.hint.hint1': "The numbers 1,4 and 9 are used each day. Only the other number differs. If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.",
                    'translate.open_the_door_simple.guest.hint.hint2': "The time on the clock can be used to determine one of the numbers of the code for that day. For example, if the time on one of the days was 8 o'clock, one of the numbers will be an 8.",
                    'translate.open_the_door_simple.guest.hint.solution': "1459 is the code for today. <br> Each day, John does four things in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. So Friday the last thing he did was looking at the clock, the time was 8. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5.",
                    'translate.open_the_door_simple.message1.title': "We need to get in. He texted me over the last couple of days, but his previous codes aren't working. I'll send you his messages",
                    'translate.open_the_door_simple.message2.title': "I need the code he used today to open his door. The code has 4 digits.",
                    'translate.open_the_door_simple.message2.error': "The door doesn't open. Is there another code you can think of?",
                    'translate.open_the_door_simple.message3.title': "Thanks! That's the right code, I can open the door now.",
                    'translate.open_the_door_simple.guest_message1.title': "We need to get in. He texted me over the last couple of days, but his previous codes aren't working. I'll send you his messages",
                    'translate.open_the_door_simple.guest_message2.title': "I need the code he used today to open his door. The code has 4 digits.",
                    'translate.open_the_door_simple.guest_message2.error': "The door doesn't open. Is there another code you can think of?",
                    'translate.open_the_door_simple.guest_message3.title': "Thanks! That's the right code, I can open the door now.",

                    'translate.open_the_door_easy.timer.title': "1. Open the Door - A Toxic Case",
                    'translate.open_the_door_easy.timer.subtitle': "The challenge will start in:",
                    'translate.open_the_door_easy.player.timer.message1': 'He probably knew I could access his footage, so there must be a connection between the things he is doing in the hallway and the code for that day.',
                    'translate.open_the_door_easy.player.timer.message2': 'I think I found something. Maybe you have already figured this out, but if you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
                    'translate.open_the_door_easy.player.timer.message3': 'Check the clock times. He is using the time on the clock as part of the code for that day',
                    'translate.open_the_door_easy.guest.timer.message1': 'He probably knew I could access his footage, so there must be a connection between the things he is doing in the hallway and the code for that day.',
                    'translate.open_the_door_easy.guest.timer.message2': 'I think I found something. Maybe you have already figured this out, but if you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
                    'translate.open_the_door_easy.guest.timer.message3': 'Check the clock times. He is using the time on the clock as part of the code for that day',
                    'translate.open_the_door_easy.player.hint.explanation': "You need to enter a 4-digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage from those days. Each day, John does four things. Do you see a pattern? And can you connect this to the door codes?",
                    'translate.open_the_door_easy.player.hint.hint1': "If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. Can you match this to the security footage?",
                    'translate.open_the_door_easy.player.hint.hint2': "John uses the time on the clock as part of his code for that day",
                    'translate.open_the_door_easy.player.hint.solution': "1459 is the code for today. <br> Each day, John does four things in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. So Friday the last thing he did was looking at the clock, the time was 8. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5.",
                    'translate.open_the_door_easy.guest.hint.explanation': "You need to enter a 4-digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage from those days. Each day, John does four things. Do you see a pattern? And can you connect this to the door codes?",
                    'translate.open_the_door_easy.guest.hint.hint1': "If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. Can you match this to the security footage?",
                    'translate.open_the_door_easy.guest.hint.hint2': "John uses the time on the clock as part of his code for that day.",
                    'translate.open_the_door_easy.guest.hint.solution': "1459 is the code for today. <br> Each day, John does four things in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. So Friday the last thing he did was looking at the clock, the time was 8. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5.",
                    'translate.open_the_door_easy.message1.title': "We need to get in. He texted me over the last couple of days, but his previous codes aren't working. I'll send you his messages.",
                    'translate.open_the_door_easy.message2.title': "I need the code he used today to open his door. The code has 4 digits.",
                    'translate.open_the_door_easy.message2.error': "The door doesn't open. Is there another code you can think of?",
                    'translate.open_the_door_easy.message3.title': "Thanks! That's the right code, I can open the door now.",
                    'translate.open_the_door_easy.guest_message1.title': "We need to get in. He texted me over the last couple of days, but his previous codes aren't working. I'll send you his messages",
                    'translate.open_the_door_easy.guest_message2.title': "I need the code he used today to open his door. The code has 4 digits.",
                    'translate.open_the_door_easy.guest_message2.error': "The door doesn't open. Is there another code you can think of?",
                    'translate.open_the_door_easy.guest_message3.title': "Thanks! That's the right code, I can open the door now.",

                    'translate.open_the_door_medium.player.timer.message1': 'I think I found something. Maybe you have already figured this out, but if you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
                    'translate.open_the_door_medium.player.timer.message2': 'Check the clock times. He is using the time on the clock in the code for that day',
                    'translate.open_the_door_medium.guest.timer.message1': 'I think I found something. Maybe you have already figured this out, but if you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
                    'translate.open_the_door_medium.guest.timer.message2': 'Check the clock times. He is using the time on the clock in the code for that day',
                    'translate.open_the_door_medium.player.hint.explanation': "We are looking for the 4 digit code that John used today. Try to connect the code for each day with what happens in the video.",
                    'translate.open_the_door_medium.player.hint.hint1': "If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. Can you match this to the security footage?",
                    'translate.open_the_door_medium.player.hint.hint2': "The time on the clock is connected to the code for that day.",
                    'translate.open_the_door_medium.player.hint.solution': "1459 is the code for today. <br> John does 4 things everyday in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. On Friday the last thing he did was looking at the clock, when the time was 8 o'clock. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5. If you used this solution, we suggest you switch the diffulty back to standard for a better experience.",
                    'translate.open_the_door_medium.guest.hint.explanation': "We are looking for the 4 digit code that John used today. Try to connect the code for each day with what happens in the video.",
                    'translate.open_the_door_medium.guest.hint.hint1': "If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. Can you match this to the security footage?",
                    'translate.open_the_door_medium.guest.hint.hint2': "The time on the clock is connected to the code for that day.",
                    'translate.open_the_door_medium.guest.hint.solution': "1459 is the code for today. <br> John does 4 things everyday in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. On Friday the last thing he did was looking at the clock, when the time was 8 o'clock. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5. If you used this solution, we suggest you switch the diffulty back to standard for a better experience.",
                    'translate.open_the_door_medium.message1.title': "We need to get in. Please help me. I have found some security footage of John's door from the last couple of days. I'll send you his texts as well.",
                    'translate.open_the_door_medium.message2.title': "I need a code to open his door. Can you find out what today's code would be?",
                    'translate.open_the_door_medium.message2.error': "The door doesn't open. Is there another code you can think of?",
                    'translate.open_the_door_medium.message3.title': "Thanks! That's the right code, I can open the door now.",
                    'translate.open_the_door_medium.guest_message1.title': "We need to get in. Please help me. I have found some security footage of John's door from the last couple of days. I'll send you his texts as well.",
                    'translate.open_the_door_medium.guest_message2.title': "I need a code to open his door. Can you find out what today's code would be?",
                    'translate.open_the_door_medium.guest_message2.error': "The door doesn't open. Is there another code you can think of?",
                    'translate.open_the_door_medium.guest_message3.title': "Thanks! That's the right code, I can open the door now.",

                    'translate.password_simple.timer.title': "3. Password - A Toxic Case",
                    'translate.password_simple.timer.subtitle': "The challenge will start in:",
                    'translate.password_simple.player.timer.message1': 'Maybe you already figured this out, but I think he left these markers intentionally. But how are these markers connected to the symbols?',
                    'translate.password_simple.player.timer.message2': 'There are 5 markers scattered around. One of them is pointing at a watch. The watch is in the spot marked by the X.',
                    'translate.password_simple.player.timer.message3': 'I think the markers are pointing at a watch, a bowl, a vase, a book and a bottle. For watch the 2nd letter is an "a", so the word should start with "a"',
                    'translate.password_simple.guest.timer.message1': 'Maybe you already figured this out, but I think he left these markers intentionally. But how are these markers connected to the symbols?',
                    'translate.password_simple.guest.timer.message2': 'There are 5 markers scattered around. One of them is pointing at a watch. The watch is in the spot marked by the X.',
                    'translate.password_simple.guest.timer.message3': 'I think the markers are pointing at a watch, a bowl, a vase, a book and a bottle. For watch the 2nd letter is an "a", so the word should start with "a"',
                    'translate.password_simple.player.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room. A photo gives a clue how to find the 5 different items. There is a map that tells you more about their position in the room. The other note shows you which letter to use from each item.",
                    'translate.password_simple.player.hint.hint1': "Focus on the markers. There are 5 of them in the room. One of them is pointing at a watch. The watch is located in the spot marked by the X.",
                    'translate.password_simple.player.hint.hint2': "One marker is pointing at a watch on position X, so we need to use the 2nd letter: 'a'. The other markers point at a bowl, a vase, a book and a bottle.",
                    'translate.password_simple.player.hint.solution': "The password is awake. <br> One note shows a rough layout of the room. The symbols tell you where the markers are located. On each location there is a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the X, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of the triangle, points at a bowl (3rd letter of bowl: w). I is the marker pointing at the vase (2nd letter of vase: a), the dot points at a book (4th letter of book:k), and the marker on the location of the square symbol points at a bottle (6th letter of bottle: e).",
                    'translate.password_simple.guest.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room. A photo gives a clue how to find the 5 different items. There is a map that which you more about their position in the room. The other note shows you which letter to use from each item.",
                    'translate.password_simple.guest.hint.hint1': "Focus on the markers. There are 5 of them in the room. One of them is pointing at a watch. The watch is located in the spot marked by the X.",
                    'translate.password_simple.guest.hint.hint2': "One marker is pointing at a watch on position X, so we need to use the 2nd letter: 'a'. The other markers point at a bowl, a vase, a book and a bottle. ",
                    'translate.password_simple.guest.hint.solution': "The password is awake. <br> One note shows a rough layout of the room. The symbols tell you where the markers are located. On each location there is a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the X, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of the triangle, points at a bowl (3rd letter of bowl: w). I is the marker pointing at the vase (2nd letter of vase: a), the dot points at a book (4th letter of book:k), and the marker on the location of the square symbol points at a bottle (6th letter of bottle: e).",
                    'translate.password_simple.message1.title': "Can't login. He left these notes for me, I'll send them over.",
                    'translate.password_simple.message2.title': "We need a 5 letter password. I think we need to find 5 of these items and use one letter from each item to make a word. But which item matches which symbol?",
                    'translate.password_simple.message2.error': "Wrong password. We have to try again.",
                    'translate.password_simple.message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",
                    'translate.password_simple.guest_message1.title': "Can't login. He left these notes for me, I'll send them over.",
                    'translate.password_simple.guest_message2.title': "We need a 5 letter password. I think we need to find 5 of these items and use one letter from each item to make a word. But which item matches which symbol?",
                    'translate.password_simple.guest_message2.error': "Wrong password. We have to try again.",
                    'translate.password_simple.guest_message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",

                    'translate.password_easy.timer.title': "3. Password - A Toxic Case",
                    'translate.password_easy.timer.subtitle': "The challenge will start in:",
                    'translate.password_easy.player.timer.message1': 'I have found something. There are 5 markers lying around, all in very specific positions.',
                    'translate.password_easy.player.timer.message2': 'I think I got one. A marker points to a watch on location X. The 2nd letter of watch is an A, so the 5 letter word that we are looking for starts with an A.',
                    'translate.password_easy.guest.timer.message1': 'I have found something. There are 5 markers lying around, all in very specific positions.',
                    'translate.password_easy.guest.timer.message2': 'I think I got one. A marker points to a watch on location X. The 2nd letter of watch is an A, so the 5 letter word that we are looking for starts with an A.',
                    'translate.password_easy.player.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room and use one letter from each of these items. You have a photo that helps you to find the 5 items and a note to determine the position of these items in the room.  ",
                    'translate.password_easy.player.hint.hint1': "Focus on finding the 5 markers. Where are they located and where do they point to?",
                    'translate.password_easy.player.hint.hint2': "A marker points to a watch on location X. The 2nd letter of watch is an A, so the 5 letter word that you are looking for starts with an A.",
                    'translate.password_easy.player.hint.solution': "The password is awake. <br> One note shows a rough layout of the room. The symbols tell you where the markers are located. On each location there is a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the X, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of the triangle, points at a bowl (3rd letter of bowl: w). I is the marker pointing at the vase (2nd letter of vase: a), the dot points at a book (4th letter of book:k), and the marker on the location of the square symbol points at a bottle (6th letter of bottle: e).",
                    'translate.password_easy.guest.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room and use one letter from each of these items. You have a photo that helps you to find the 5 items and a note to determine the position of these items in the room.",
                    'translate.password_easy.guest.hint.hint1': "Focus on finding the 5 markers. Where are they located and where do they point to?",
                    'translate.password_easy.guest.hint.hint2': "A marker points to a watch on location X. The 2nd letter of watch is an A, so the 5 letter word that you are looking for starts with an A.",
                    'translate.password_easy.guest.hint.solution': "The password is awake. <br> One note shows a rough layout of the room. The symbols tell you where the markers are located. On each location there is a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the X, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of the triangle, points at a bowl (3rd letter of bowl: w). I is the marker pointing at the vase (2nd letter of vase: a), the dot points at a book (4th letter of book:k), and the marker on the location of the square symbol points at a bottle (6th letter of bottle: e).",
                    'translate.password_easy.message1.title': "Can't login. He left these notes for me, but it just doesn't make any sense to me.",
                    'translate.password_easy.message2.title': "I think that we are looking for a 5 letter password. Can you figure it out?",
                    'translate.password_easy.message2.error': "Wrong password. We have to try again.",
                    'translate.password_easy.message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",
                    'translate.password_easy.guest_message1.title': "Can't login. I need his password. He left these notes for me, but it just doesn't make any sense.",
                    'translate.password_easy.guest_message2.title': "I think that we are looking for a 5 letter password. Can you figure it out?",
                    'translate.password_easy.guest_message2.error': "Wrong password. We have to try again.",
                    'translate.password_easy.guest_message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",

                    'translate.password_medium.player.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items are hidden in the room and use a letter from each item.",
                    'translate.password_medium.player.hint.hint1': "Focus on the markers.",
                    'translate.password_medium.player.hint.hint2': "One of the markers points at a watch. Where is it located? The password starts with A...",
                    'translate.password_medium.player.hint.solution': "The password is awake. <br> The note with 1 to 5 shows the order of the items and the different locations in the room where the items you need are located. Each location has a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the 1, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of 2, points at a bowl (3rd letter of bowl: w). 3 is the marker pointing at the vase (2nd letter of vase: a), 4 at a book (4th letter of book:k), and 5 at a bottle (6th letter of bottle: e).",
                    'translate.password_medium.guest.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room and use a letter from each item.",
                    'translate.password_medium.guest.hint.hint1': "Focus on the markers.",
                    'translate.password_medium.guest.hint.hint2': "One of the markers points at a watch. Where is it located? The password starts with A...",
                    'translate.password_medium.guest.hint.solution': "The password is awake. <br> The note with 1 to 5 shows the order of the items and the different locations in the room where the items you need are located. Each location has a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the 1, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of 2, points at a bowl (3rd letter of bowl: w). 3 is the marker pointing at the vase (2nd letter of vase: a), 4 at a book (4th letter of book:k), and 5 at a bottle (6th letter of bottle: e).",
                    'translate.password_medium.message1.title': "Can't login. I need his password. He left these notes for me, but it just doesn't make any sense.",
                    'translate.password_medium.message2.title': "I see a lot of stuff lying around. Can you figure out a password?",
                    'translate.password_medium.message2.error': "Wrong password. We have to try another.",
                    'translate.password_medium.message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",
                    'translate.password_medium.guest_message1.title': "Can't login. I need his password. He left these notes for me, but it just doesn't make any sense.",
                    'translate.password_medium.guest_message2.title': "I see a lot of stuff lying around. Can you figure out a password?",
                    'translate.password_medium.guest_message2.error': "Wrong password. We have to try another.",
                    'translate.password_medium.guest_message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",

                    'translate.poison_simple.timer.title': "5. Poison - A Toxic Case",
                    'translate.poison_simple.timer.subtitle': "The challenge will start in:",
                    'translate.poison_simple.player.timer.message1': 'The size of his pupils looks regular to me. But how did he come into contact with the poison?',
                    'translate.poison_simple.player.timer.message2': 'There is sweat on his forehead. An effect of the poison perhaps?',
                    'translate.poison_simple.guest.timer.message1': 'The size of his pupils looks regular to me. But how did he come into contact with the poison?',
                    'translate.poison_simple.guest.timer.message2': 'There is sweat on his forehead. An effect of the poison perhaps?',
                    'translate.poison_simple.player.hint.explanation': "You need to enter the number of the poison that was used. The poison has five different attributes (the five columns in the table). Each player has different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
                    'translate.poison_simple.player.hint.hint1': "There are 5 clues to find in the video. There is a coffee cup on the floor, he has sweat on his forehead, his fist is clenched, he is drooling and you can see the shape of his pupils.",
                    'translate.poison_simple.player.hint.hint2': "The coffee cup suggests that his mouth came into contact with the poison. His pupil size is regular.",
                    'translate.poison_simple.player.hint.solution': "71164 matches this situation. <br> John has a closed fist (pugnus - asphyxiate), is drooling (kitosis - minutes), has regular pupils (other type) and sweat on his forehead (synthetic). The coffee cup indicates his mouth came into contact with the poison.",
                    'translate.poison_simple.guest.hint.explanation': "You need to enter the number of the poison that was used. The poison has five different attributes (the five columns in the table). Each player has different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
                    'translate.poison_simple.guest.hint.hint1': "There are 5 clues to find in the video. There is a coffee cup on the floor, he has sweat on his forehead, his fist is clenched, he is drooling and you can see the shape of his pupils.",
                    'translate.poison_simple.guest.hint.hint2': "The coffee cup suggests that his mouth came into contact with the poison. His pupil size is regular.",
                    'translate.poison_simple.guest.hint.solution': "71164 matches this situation. <br> John has a closed fist (pugnus - asphyxiate), is drooling (kitosis - minutes), has regular pupils (other type) and sweat on his forehead (synthetic). The coffee cup indicates his mouth came into contact with the poison.",
                    'translate.poison_simple.message1.title': "He is dead. There is no sign of blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_simple.message2.title': "Can you find a poison that matches this situation? Just send me the number.",
                    'translate.poison_simple.message2.error': "That poison doesn't match what I'm seeing at the crime scene. We're missing something...",
                    'translate.poison_simple.message3.title': "This matches all his symptoms. I think it is the same poison that killed the other two people. This isn’t an off-the-shelf poison. Maybe it reacts to another substance?",
                    'translate.poison_simple.guest_message1.title': "He is dead. There is no sign of  blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_simple.guest_message2.title': "Can you find a poison that matches this situation? Just send me the number.",
                    'translate.poison_simple.guest_message2.error': "That poison doesn't match what I'm seeing at the crime scene. We're missing something...",
                    'translate.poison_simple.guest_message3.title': "This matches all his symptoms. I think it is the same poison that killed the other two people. This isn’t an off-the-shelf poison. Maybe it reacts to another substance?",

                    'translate.poison_easy.timer.title': "5. Poison - A Toxic Case",
                    'translate.poison_easy.timer.subtitle': "The challenge will start in:",
                    'translate.poison_easy.player.timer.message1': 'The size of his pupils looks regular to me. But how did he come into contact with the poison?',
                    'translate.poison_easy.player.timer.message2': 'There is sweat on his forehead. An effect of the poison perhaps?',
                    'translate.poison_easy.guest.timer.message1': 'The size of his pupils looks regular to me. But how did he come into contact with the poison?',
                    'translate.poison_easy.guest.timer.message2': 'There is sweat on his forehead. An effect of the poison perhaps?',
                    'translate.poison_easy.player.hint.explanation': "You need to enter the number of the poison that was used. The poison has five different attributes (the five columns in the table). Each player has different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
                    'translate.poison_easy.player.hint.hint1': "The size of his pupils is regular. But how did he came into contact with the poison?",
                    'translate.poison_easy.player.hint.hint2': "There are 5 clues to find in the video. There is a coffee cup on the floor, he has sweat on his forehead, his fist is clenched, he is drooling and you can see the size of his pupils.The coffee cup indicates that the his mouth came into contact with the poison.",
                    'translate.poison_easy.player.hint.solution': "71164 matches this situation. <br> John has a closed fist (pugnus - asphyxiate), is drooling (kitosis - minutes), has regular pupils (other type) and sweat on his forehead (synthetic). The coffee cup suggests that his mouth came into contact with the poison",
                    'translate.poison_easy.guest.hint.explanation': "You need to enter the number of the poison that was used. The poison has five different attributes (the five columns in the table). Each player has different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
                    'translate.poison_easy.guest.hint.hint1': "The size of his pupils is regular. But how did he came into contact with the poison?",
                    'translate.poison_easy.guest.hint.hint2': "There are 5 clues to find in the video. There is a coffee cup on the floor, he has sweat on his forehead, his fist is clenched, he is drooling and you can see the size of his pupils.The coffee cup suggests that his mouth came into contact with the poison.",
                    'translate.poison_easy.guest.hint.solution': "71164 matches this situation. <br> John has a closed fist (pugnus - asphyxiate), is drooling (kitosis - minutes), has regular pupils (other type) and sweat on his forehead (synthetic). The coffee cup indicates his mouth came into contact with the poison.",
                    'translate.poison_easy.message1.title': "He is dead. There is no sign of  blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_easy.message2.title': "Can you find a poison that matches this situation? Just send me the number.",
                    'translate.poison_easy.message2.error': "That poison doesn't match what I'm seeing at the crime scene. We're missing something...",
                    'translate.poison_easy.message3.title': "This matches all his symptoms. I think it is the same poison that killed the other two people. This isn’t an off-the-shelf poison. Maybe it reacts to another substance?",
                    'translate.poison_easy.guest_message1.title': "He is dead. There is no sign of blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_easy.guest_message2.title': "Can you find a poison that matches this situation? Just send me the number.",
                    'translate.poison_easy.guest_message2.error': "That poison doesn't match what I'm seeing at the crime scene. We're missing something...",
                    'translate.poison_easy.guest_message3.title': "This matches all his symptoms. I think it is the same poison that killed the other two people. This isn’t an off-the-shelf poison. Maybe it reacts to another substance?",

                    'translate.poison_medium.player.hint.explanation': "You need to enter the number of the poison that was used. You will need to combine the information that each player has with clues that you see on the video.",
                    'translate.poison_medium.player.hint.hint1': "There are 5 clues to find in the video.",
                    'translate.poison_medium.player.hint.hint2': "He is sweating, which is a sign of stress. His pupil size is regular. What does the coffee cup tell you?",
                    'translate.poison_medium.player.hint.solution': "71164 matches this situation. <br> John come into contact with the poison by mouth (coffee cup) and was sweating (a sign of stress, so the poison had an effect after minutes or hours). He has a fist (so not corrosive) and has a regular pupil size (but Neurotic and herb is not possible because it comes through the skin) so it must by synthetic and not Neurotic. He was drooling, but the Cardiac types with X or C use herbs, so it cannot be Cardiac.",
                    'translate.poison_medium.guest.hint.explanation': "You need to enter the number of the poison that was used. You will need to combine the information that each player has with clues that you see on the video.",
                    'translate.poison_medium.guest.hint.hint1': "There are 5 clues to find in the video.",
                    'translate.poison_medium.guest.hint.hint2': "He is sweating, which is a sign of stress. His pupil size is regular. What does the coffee cup tell you?",
                    'translate.poison_medium.guest.hint.solution': "71164 matches this situation. <br> John come into contact with the poison by mouth (coffee cup) and was sweating (a sign of stress, so the poison had an effect after minutes or hours). He has a fist (so not corrosive) and has a regular pupil size (but Neurotic and herb is not possible because it comes through the skin) so it must by synthetic and not Neurotic. He was drooling, but the Cardiac types with X or C use herbs, so it cannot be Cardiac.",
                    'translate.poison_medium.message1.title': "He is dead. No blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_medium.message2.error': "That poison doesn't match what I'm seeing in the crime scene. We're missing something...",
                    'translate.poison_medium.message3.title': "This matched all his symptoms. I think it is the same poison that killed the other two people. It's not an off the shelf poison, must be a combination of chemicals that have this effect.",
                    'translate.poison_medium.message2.title': "Can you tell me if there is a poison that matches this situation?",
                    'translate.poison_medium.guest_message1.title': "He is dead. No blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_medium.guest_message2.title': "Can you tell me if there is a poison that matches this situation?",
                    'translate.poison_medium.guest_message2.error': "That poison doesn't match what I'm seeing in the crime scene. We're missing something...",
                    'translate.poison_medium.guest_message3.title': "This matched all his symptoms. I think it is the same poison that killed the other two people. It's not an off the shelf poison, must be a combination of chemicals that have this effect.",

                    'translate.tools_and_wires_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9-square512.webp",
                    'translate.tools_and_wires_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9mainmanual.webp",
                    'translate.tools_and_wires_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9mainmobile.webp",
                    'translate.tools_and_wires_simple.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9maintimer.webp",
                    'translate.tools_and_wires_simple.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9mainnail.webp",
                    'translate.tools_and_wires_simple.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9mainclay.webp",
                    'translate.tools_and_wires_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9guestmanual.webp",
                    'translate.tools_and_wires_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9guestswitch.webp",
                    'translate.tools_and_wires_simple.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9guestfuse.webp",
                    'translate.tools_and_wires_simple.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9guestoil.webp",
                    'translate.tools_and_wires_simple.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/9guestgas.webp",
                    'translate.tools_and_wires_simple.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 clues shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest. The bomb guide will tell you the order in which these items were used.",
                    'translate.tools_and_wires_simple.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires and the phone is connected to a grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_simple.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combinations can be made?",
                    'translate.tools_and_wires_simple.player.hint.solution': "clay bomb - white wire - timer, oil bomb - green wire - fuse, nail bomb - orange wire - switch, gas bomb - grey wire - mobile. Each bomb uses one wire of a different colour. The mobile phone has a grey wire, so it cannot connect with the oil bomb (green wire), nor the clay bomb (white wire), nor the nail bomb (connected to a switch). The only match is the gas bomb. The oil bomb has a green wire, so the detonator cannot be a timer (grey,orange or white). The switch is connected to a nail bomb, so the only detonator the oil bomb could have is a fuse. The clay bomb has a white wire, the other wire colours are now used and the switch is connected to a nail bomb. So the clay bomb uses a timer. This leaves the nail bomb, with a switch and the remaning orange wire. The white wire is part of the easiest bomb and the gas bomb is the most difficult. In between are the other two boms, where the one with the fuse is easier than then the one with a switch.",
                    'translate.tools_and_wires_simple.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 clues shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest. The bomb guide will tell you the order in which these items were used.",
                    'translate.tools_and_wires_simple.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires and the phone is connected to a grey wire. The switch is connected to a nail bomb. ",
                    'translate.tools_and_wires_simple.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combinations can be made?",
                    'translate.tools_and_wires_simple.guest.hint.solution': "clay bomb - white wire - timer, oil bomb - green wire - fuse, nail bomb - orange wire - switch, gas bomb - grey wire - mobile. Each bomb uses one wire of a different colour. The mobile phone has a grey wire, so it cannot connect with the oil bomb (green wire), nor the clay bomb (white wire), nor the nail bomb (connected to a switch). The only match is the gas bomb. The oil bomb has a green wire, so the detonator cannot be a timer (grey,orange or white). The switch is connected to a nail bomb, so the only detonator the oil bomb could have is a fuse. The clay bomb has a white wire, the other wire colours are now used and the switch is connected to a nail bomb. So the clay bomb uses a timer. This leaves the nail bomb, with a switch and the remaning orange wire. The white wire is part of the easiest bomb and the gas bomb is the most difficult. In between are the other two boms, where the one with the fuse is easier than then the one with a switch.",
                    'translate.tools_and_wires_simple.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.tools_and_wires_simple.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out the matching wire and detonator. Let me know in what order she made these bombs.",
                    'translate.tools_and_wires_simple.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again? Use the TAG ANSWER link above.",
                    'translate.tools_and_wires_simple.message3.title': "OK, this makes sense. Now I can look up how to defuse them. Let's go!",
                    'translate.tools_and_wires_simple.guest_message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.tools_and_wires_simple.guest_message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out the matching wire and detonator. Let me know in what order she made these bombs.",
                    'translate.tools_and_wires_simple.guest_message2.error': "No, it's not right. I see some combinations that are possible. Can you check again? Use the TAG ANSWER link above.",
                    'translate.tools_and_wires_simple.guest_message3.title': "OK, this makes sense. Now I can look up how to defuse them. Let's go!",

                    'translate.who_did_it_simple.timer.title': "6. Who did it - A Toxic Case",
                    'translate.who_did_it_simple.timer.subtitle': "The challenge will start in:",
                    'translate.who_did_it_simple.player.timer.message1': '{{playerName}}, can you focus on the number of cups at the beginning and end of each video? I will try to make some notes as well.',
                    'translate.who_did_it_simple.player.timer.message2': 'I have made some notes that I can share with you. 31 ends with one cup and no doormat. 98 starts with 2 cups and no mat, and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.',
                    'translate.who_did_it_simple.player.timer.message3': 'The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.',
                    'translate.who_did_it_simple.guest.timer.message1': '{{playerName}}, can you check if there is a doormat at the start and end of each video? I will try to make some notes as well.',
                    'translate.who_did_it_simple.guest.timer.message2': 'I have made some notes that I can share with you. 31 ends with one cup and no doormat. 98 starts with 2 cups and no mat, and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.',
                    'translate.who_did_it_simple.guest.timer.message3': 'The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.',
                    'translate.who_did_it_simple.player.hint.explanation': "There are six videos that need to be placed in the right order. Maybe one of you can focus on the doormat on the right and the other on the number of coffee cups. What is there at the start and what at the end of each video?",
                    'translate.who_did_it_simple.player.hint.hint1': "31 ends with one cup but without a doormat. 98 starts with 2 cups without the mat and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.",
                    'translate.who_did_it_simple.player.hint.hint2': "The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
                    'translate.who_did_it_simple.player.hint.solution': "The order is 31-84-22-17-98-56. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. There is only one possible order. 31: start (0 cups, a mat), end (1 cup, no mat). 84 (start: 1 cup, no mat), end (2 cups, a mat). 22: start (2 cups, a mat), end (3 cups, a mat). 17: start (3 cups, a mat), end (2 cups, no mat). 98: start (2 cups no mat), end (1 cup, a mat). 56: start (1 cup, a mat) end (1 cup, a mat).",
                    'translate.who_did_it_simple.guest.hint.explanation': "There are six videos that need to be placed in the right order. Maybe one of you can focus on the doormat on the right and the other on the number of coffee cups. What is there at the start and what at the end of each video?",
                    'translate.who_did_it_simple.guest.hint.hint1': "31 ends with one cup but without a doormat. 98 starts with 2 cups without the mat and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.",
                    'translate.who_did_it_simple.guest.hint.hint2': "The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 21487. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
                    'translate.who_did_it_simple.guest.hint.solution': "The order is 31-84-22-17-98-56. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. There is only one possible order. 31: start (0 cups, a mat), end (1 cup, no mat). 84 (start: 1 cup, no mat), end (2 cups, a mat). 22: start (2 cups, a mat), end (3 cups, a mat). 17: start (3 cups, a mat), end (2 cups, no mat). 98: start (2 cups no mat), end (1 cup, a mat). 56: start (1 cup, a mat) end (1 cup, a mat).",
                    'translate.who_did_it_simple.message1.title': "There was a security camera in the corner. Still works with disks. If we know the right order of these recordings, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_simple.message2.title': "See if you can find something which can help us place the recordings in the right order.",
                    'translate.who_did_it_simple.message2.error': "This isn't the correct order, I see some changes between recordings I cannot explain. Can you check again? Use the TAG ANSWER link above.",
                    'translate.who_did_it_simple.message3.title': "Makes sense. Let me try arranging the recordings in that order.",
                    'translate.who_did_it_simple.guest_message1.title': "There was a security camera in the corner. Still works with disks. If we know the right order of these recordings, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_simple.guest_message2.title': "See if you can find something which can help us place the recordings in the right order.",
                    'translate.who_did_it_simple.guest_message2.error': "This isn't the correct order, I see some changes between recordings I cannot explain. Can you check again? Use the TAG ANSWER link above.",
                    'translate.who_did_it_simple.guest_message3.title': "Makes sense. Let me try arranging the recordings in that order.",

                    'translate.who_did_it_easy.timer.title': "6. Who did it - A Toxic Case",
                    'translate.who_did_it_easy.timer.subtitle': "The challenge will start in:",
                    'translate.who_did_it_easy.player.timer.message1': 'I have made some notes that I can share with you. The first video starts with no coffee cups on the counter and a doormat to the right. It ends with 1 cup on the counter and without a doormat.',
                    'translate.who_did_it_easy.player.timer.message2': 'The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.',
                    'translate.who_did_it_easy.guest.timer.message1': 'I have made some notes that I can share with you. The first video starts with no coffee cups on the counter and a doormat to the right. It ends with 1 cup on the counter and without a doormat.',
                    'translate.who_did_it_easy.guest.timer.message2': 'The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.',
                    'translate.who_did_it_easy.player.hint.explanation': "There are six videos that need to be placed in the right order. Maybe one of you can focus on the doormat on the right and the other on the number of coffee cups. What is there at the start and what at the end of each video?",
                    'translate.who_did_it_easy.player.hint.hint1': "The first video starts with no coffee cups on the counter and a doormat to the right. It ends with 1 cup on the counter and without a doormat.",
                    'translate.who_did_it_easy.player.hint.hint2': "The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
                    'translate.who_did_it_easy.player.hint.solution': "The order is 31-84-22-17-98-56. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. There is only one possible order. 31: start (0 cups, a mat), end (1 cup, no mat). 84 (start: 1 cup, no mat), end (2 cups, a mat). 22: start (2 cups, a mat), end (3 cups, a mat). 17: start (3 cups, a mat), end (2 cups, no mat). 98: start (2 cups no mat), end (1 cup, a mat). 56: start (1 cup, a mat) end (1 cup, a mat).",
                    'translate.who_did_it_easy.guest.hint.explanation': "There are six videos that need to be placed in the right order. Maybe one of you can focus on the doormat on the right and the other on the number of coffee cups. What is there at the start and what at the end of each video?",
                    'translate.who_did_it_easy.guest.hint.hint1': "The first video starts with no coffee cups on the counter and a doormat to the right. It ends with 1 cup on the counter and without a doormat.",
                    'translate.who_did_it_easy.guest.hint.hint2': "The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
                    'translate.who_did_it_easy.guest.hint.solution': "The order is 31-84-22-17-98-56. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. There is only one possible order. 31: start (0 cups, a mat), end (1 cup, no mat).  84 (start: 1 cup, no mat), end (2 cups, a mat).  22: start (2 cups, a mat), end (3 cups, a mat). 17: start (3 cups, a mat), end (2 cups, no mat). 98: start (2 cups no mat), end (1 cup, a mat). 56: start (1 cup, a mat) end (1 cup, a mat).",
                    'translate.who_did_it_easy.message1.title': "There was a security camera in the corner. Still works with disks. I have found some recordings.",
                    'translate.who_did_it_easy.message2.title': "If we know the right order of the recordings, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_easy.message2.error': "This isn't the correct order, I see some changes between recordings I cannot explain. Can you check again? Use the TAG ANSWER link above.",
                    'translate.who_did_it_easy.message3.title': "Makes sense. Let me try arranging the recordings in that order.",
                    'translate.who_did_it_easy.guest_message1.title': "There was a security camera in the corner. Still works with disks. I have found some recordings.",
                    'translate.who_did_it_easy.guest_message2.title': "If we know the right order of the recordings, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_easy.guest_message2.error': "This isn't the correct order, I see some changes between recordings I cannot explain. Can you check again? Use the TAG ANSWER link above.",
                    'translate.who_did_it_easy.guest_message3.title': "Makes sense. Let me try arranging the recordings in that order.",


                    'translate.who_did_it_medium.player.hint.explanation': "First, you will need to place the six videos in the right order. Look at the situation at the start and at the end of each video? If you know the order try to match the names to each video",
                    'translate.who_did_it_medium.player.hint.hint1': "The first video is the one where there are no coffee cups at the start and ends with 1 cup and a doormat. Focus on the cups and the doormat to determine the order.",
                    'translate.who_did_it_medium.player.hint.hint2': "The order is 31831-48721-21487-17517-98712-17756. Two people are using a cup that was placed on the counter before by someone else.",
                    'translate.who_did_it_medium.player.hint.solution': "The order is Bruce, George, Evan, David, Henry and Linda. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. Only one order is possible. 31831: start (0 cups, a mat), end (1 cup, no mat).  48721 (start: 1 cup, no mat), end (2 cups, a mat).  21487: start (2 cups, a mat), end (3 cups, a mat). 17517: start (3 cups, a mat), end (2 cups, no mat). 98712: start (2 cups no mat), end (1 cup, a mat). 17756: start (1 cup, a mat) end (1 cup, a mat). <br><br> 98712 uses the cup from 21487 and drinks water, so 21487 must be Evan. 17517 (David) uses the cup from 31831 (Bruce) to make coffee. George is the one cleaning before he puts the cup on the counter (48732), that leaves Henry (98712, drinks water) and Linda",
                    'translate.who_did_it_medium.guest.hint.explanation': "First, you will need to place the six videos in the right order. Look at the situation at the start and at the end of each video? If you know the order try to match the names to each video",
                    'translate.who_did_it_medium.guest.hint.hint1': "The first video is the one where there are no coffee cups at the start and ends with 1 cup and a doormat. Focus on the cups and the doormat to determine the order.",
                    'translate.who_did_it_medium.guest.hint.hint2': "The order is 31831-48721-21487-17517-98712-17756. Two people are using a cup that was placed on the counter before by someone else.",
                    'translate.who_did_it_medium.guest.hint.solution': "The order is Bruce, George, Evan, David, Henry and Linda. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. Only one order is possible. 31831: start (0 cups, a mat), end (1 cup, no mat). 48721 (start: 1 cup, no mat), end (2 cups, a mat).  21487: start (2 cups, a mat), end (3 cups, a mat). 17517: start (3 cups, a mat), end (2 cups, no mat). 98712: start (2 cups no mat), end (1 cup, a mat). 17756: start (1 cup, a mat) end (1 cup, a mat). <br><br> 98712 uses the cup from 21487 and drinks water, so 21487 must be Evan. 17517 (David) uses the cup from 31831 (Bruce) to make coffee. George is the one cleaning before he puts the cup on the counter (48732), that leaves Henry (98712, drinks water) and Linda",
                    'translate.who_did_it_medium.message1.title': "There was a camera in the corner. Still works with disks. I have also found some notes from the people who work here..",
                    'translate.who_did_it_medium.message2.title': "If we know the order in which these people used the machine, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_medium.message2.error': "This isn't the correct order, can you check again? Use the TAG ANSWER link above.",
                    'translate.who_did_it_medium.message3.title': "Makes sense. Let me try arranging the recordings in that order.",
                    'translate.who_did_it_medium.guest_message1.title': "There was a camera in the corner. Still works with disks. I have also found some notes from the people who work here.",
                    'translate.who_did_it_medium.guest_message2.title': "If we know the order in which these people used the machine, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_medium.guest_message2.error': "This isn't the correct order, can you check again? Use the TAG ANSWER link above.",
                    'translate.who_did_it_medium.guest_message3.title': "Makes sense. Let me try arranging the recordings in that order.",

                },
                tagOptionsDialog: {
                    availableOptions: "Available options ({{count}})",
                    submitButton: "Submit",
                    clearBtn: "Clear",
                    selected: "Selected",
                    inOrder: "in order",
                },
                taggerCarousel: {
                    altText: "tag image",
                    confirmButton: "Confirm",
                    submitButtonText: "Submit",
                    tagAllImages: "Tag all the images"
                },
                resultsDialog: {
                    resultsTitle: "Results",
                    resultsScoreDifficultyCap: "You are two smart detectives! We believe you deserve a higher difficulty level. You will get more points and different information. And also no more help from Steve... The main player can change the difficulty level in the menu, so you can always go back to standard difficulty.",
                    hintsUsed: "Total Hints used",
                    incorrectScore: "Total Incorrect score",
                    solutionsTried: "Total Solutions tried",
                    timeSpent: "Time Spent",
                    totalPlayerHintsUsed: "Total Player Hints Used",
                    totalGuestHintsUsed: "Total Guest Hints Used",
                    totalPlayerSolutionsTried: "Total Incorrect Attempts",
                    grandTotalResults: "Grand total results",
                    totalScore: "Total score",
                    congratulationsText: "Congratulations! You've successfully cracked the case together!",
                    waitMessage: "Wait for the other player to press results and try again!",
                    goToMenu: "Go To Menu",
                    continueButton: "Continue",
                    curiousFriends: "Do you want to know your friends score?",
                    shareWithFriends: "Invite them and see how they did!",
                    shareMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for {{puzzleName}} on {{gameName}}. Do you accept this challenge? Go to {{link}}",
                    shareGrandTotalMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for on {{gameName}}. Do you accept this challenge? Go to {{link}}"
                },
                continuePuzzleVideo: {
                    'Intro 1': 'Are you both ready? Then start the next video - Intro 2 - and press Continue!',
                    'Intro 2': 'Are you both ready to start the game? Then start the next video - Open the door - and press Continue!',
                    'Deceased persons': 'Are you both ready for the next challenge? Then start the next video  - Password - and press Continue!',
                    'Differences': 'Watch the next video and press \'xxxxxxx  Differences\' if you are both ready for the next challenge!',
                    'Drone': 'Are you both ready for the next challenge? Then start the next video  - Poison - and press Continue!',
                    'Enclose': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
                    'Escape': 'Watch the next video and press \'xxxxxxx   Escape\' if you are both ready for the next challenge!',
                    'Open the door': 'Are you both ready for the next challenge? Then start the next video  - Deceased Persons - and press Continue. ',
                    'Password': 'Are you both ready for the next challenge? Then start the next video  - Drone - and press Continue!',
                    'Poison': 'Are you both ready for the next challenge? Then start the next video  - Who did it - and press Continue!',
                    'Tools and wires': 'Watch the next video and press \'xxxxxxx  Tools and wires\' if you are both ready for the next challenge!',
                    'Who did it': 'Are you both ready for the next challenge? Then start the next video  - Enclose - and press Continue!',
                },
                puzzleCard: {
                    difficultyPlayed: "Difficulty played:",
                    hintsUsedByPlayer: "Number of hints used by the player:",
                    hintsUsedByGuest: "Number of hints used by guest:",
                    solutionsTriedByPlayer: "Number of solutions tried by the player:",
                    solutionsTriedByGuest: "Number of solutions tried by the guest:",
                    expires: "Expires",
                    seeStats: "See stats"
                },
                problemModal: {
                    sorry: "We're really sorry about the hiccup! But don't worry, we're here to guide it back on track.",
                    clickContinue: "By clicking continue, you will reset this challenge and you should be able to continue.",
                    areYouSure: "Are you sure?",
                    reset: "Reset",
                    guestNeedRejoin: "The guest needs to re-join the game again. Please share the link with the guest player and ask them to join.",
                    inviteContinue: "Invited, continue!",
                    doneOrSupport: "That's it! Now let's reload the page and the game will go on. In case this issue persist, contact the support.",
                    reloadPage: "Reload page",
                    problem: "Problem?",
                },
                instructionsModal: {
                    instruction1: "How to play<br>" +
                        "<br>A Toxic Case is a two player game about a missing person case. To play the game, both players need their own smartphone. Each player will receive different information about the case and a question they will need to answer." +
                        "<br>Together you will watch a video about the case. The goal is to answer the question by discussing the information you received with the other player." +
                        "<br>The videos are shown on our CouchClues Youtube Channel. We suggest using a television, but you can watch them on any screen you like." +
                        "<br>Each video has an introduction, which is part of the story and then a case you will need to solve. Each case is repeated multiple times, so you won't have to restart the video.",
                    instruction2: "Rules and options<br>" +
                        "<br>When you start a puzzle, a timer will start. You can try to solve the puzzles as fast as possible in order to receive the most points. Or you can ignore the score and take as long as you like." +
                        "<br>If you don't understand a puzzle, you can get a hint. Each puzzle has an explanation hint, which tells you more about what you need to do, and 2 hints that help you with the actual puzzle." +
                        "<br>Both players can use the same hint or you can read the hint again, without using additional points." +
                        "<br>If the hints are not enough to solve the puzzle, you can click the Solution option to read the answer.",
                    instruction3: "Starting the game<br>" +
                        "<br>The player that has purchased the game will receive a game link, which will give them access to the game. This will be the main player." +
                        "<br>When the main player starts the game, they will be asked to share a second link with the other player. This second link allows the other player to join as guest and enter their own name." +
                        "<br>The game will start with 2 intro challenges to get you acquainted with the game" +
                        "<br>If you experience bugs or other problems, the main player can use the 'Problem' button in this menu to get you back on track.",
                    btnText: 'Continue'
                },
                navbar: {
                    inviteFriend: "Invite a friend",
                    instructions: "Instructions",
                    instructionsUrl: "https://couchclues.com/instructions/",
                    problem: "Problem?",
                    quit: "Quit",
                },
                difficultyModal: {
                    areYouSure: "Are you sure you want to change difficulty to",
                    dialogContent: "By changing the difficulty you will reset your current challenge. \n Your guest player will need to reload the page to continue. Are you sure you want to proceed?",
                    cancel: "Cancel",
                    confirm: "Confirm",
                }
            },
            nl: {
                resultsNavigation: {
                    faqUrl: "https://couchclues.com/nl/#faq",
                    shopUrl: "https://couchclues.com/nl/chapters/a-toxic-case/full/"
                },
                game: {
                    language: "Taal",
                    languageEn: "English",
                    languageNl: "Nederlands",
                    continue: "DOORGAAN!",
                    playNow: "Speel Nu",
                    seeResults: "Bekijk de score",
                    selectLanguage: "Selecteer de taal",
                    go: "DOORGAAN",
                    results: "Score",
                    completed: "Voltooid:",
                    noPuzzles: "Geen puzzels beschikbaar. Koop een spel om te beginnen met spelen!",
                    typeToAnswer: "Typ hier het antwoord",
                    clickToTag: "Klik om afbeeldingen te taggen",
                    clickToTagAnswer: "Klik om antwoorden te taggen",
                    clickToTagAnswerInOrder: "Tag de antwoorden in de juiste volgorde",
                    backToChat: "Terug naar de chat",
                    chatWith: "Chatten met",
                    difficulty: "Moeilijkheidsgraad",
                    copyLink: "Kopieer deze link en deel dit met je medespeler:",
                    copyLinkLabel: "Uitnodigingslink kopiëren",
                    inviteFriend: "Nodig een vriend uit",
                    about: "Over",
                    faq: "FAQ",
                    shop: "Shop",
                    waitForGo: "Wacht tot de andere speler op DOORGAAN drukt",
                    shareAlinkWithPlayer: "Deel een link om een andere speler uit te nodigen",
                    askPartnerToStart: "Vraag je medespeler om het spel te starten. We wachten...",
                    guestPlayerNotReady: "Je medespeler is nog niet klaar. We wachten... ",
                    minimumPlayersText: "Je hebt minimaal twee spelers nodig om het spel te spelen. {!isGuest ? ' Stuur de link naar je medespeler om te kunnen spelen ' : ''}",
                    copyInviteLinkLabel: "Uitnodigingslink kopiëren",
                    playerWaitingForPartner: " {{partnerName}} moet nog een oplossing doorsturen. Kun jij helpen?",
                    difficultyLabel: "Moeilijkheidsgraad",
                    startButtonText: "Start het spel",
                    playButtonText: "Spelen!",
                    shareLinkDialogTitle: "Deel de link hieronder om het spel te starten",
                    startGameDialogTitle: "Los het samen op!",
                    yourNameLabel: "Jouw naam",
                    getStartText: "Start het spel",
                    errorGoHome: "Terug naar Home",
                    error: "Fout",
                    notExists: "Het spel waarnaar je op zoek bent bestaat niet",
                    buyAnother: "Je moet een ander hoofdstuk kopen om dit spel te spelen",
                    youDontHave: "Je hebt geen toegang tot deze puzzel",
                    getPuzzle: "Koop hoofdstuk",
                },
                puzzle: {
                    // dutch media starts ====
                    'translate.game.toxic_case': 'A Toxic Case',

                    'translate.deceased_person_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2smainprescription.webp",
                    'translate.deceased_person_simple.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2smainoneheart.webp",
                    'translate.deceased_person_simple.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2smaintattooface.webp",
                    'translate.deceased_person_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2sguestglasses.webp",
                    'translate.deceased_person_simple.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2sguestearring.webp",
                    'translate.deceased_person_simple.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2sguestrahuljim.webp",
                    'translate.deceased_person_simple.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2sguest30years.webp",

                    'translate.deceased_person_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2mainage.webp",
                    'translate.deceased_person_easy.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2mainnotblonde.webp",
                    'translate.deceased_person_easy.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2mainnotfamily.webp",
                    'translate.deceased_person_easy.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2mainoneheart.webp",
                    'translate.deceased_person_easy.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2mainrahul.webp",
                    'translate.deceased_person_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2guestglasses.webp",
                    'translate.deceased_person_easy.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2guestheart.webp",
                    'translate.deceased_person_easy.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2guestmedicines.webp",
                    'translate.deceased_person_easy.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2guestnephew.webp",
                    'translate.deceased_person_easy.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl2guesttattoos.webp",

                    'translate.enclose_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl10sboth.webp",
                    'translate.enclose_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl10sboth.webp",

                    'translate.enclose_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl10main.webp",
                    'translate.enclose_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl10guest.webp",

                    'translate.open_the_door_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1both.webp",
                    'translate.open_the_door_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1main.webp",
                    'translate.open_the_door_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1both.webp",
                    'translate.open_the_door_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1guest.webp",

                    'translate.open_the_door_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1both.webp",
                    'translate.open_the_door_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1main.webp",
                    'translate.open_the_door_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1both.webp",
                    'translate.open_the_door_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl1guest.webp",

                    'translate.password_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3smap.webp",
                    'translate.password_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3mainsymbols.webp",
                    'translate.password_simple.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3mainitems.webp",
                    'translate.password_simple.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3marker.webp",
                    'translate.password_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3smap.webp",
                    'translate.password_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3guestsymbols.webp",
                    'translate.password_simple.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3guestitems.webp",
                    'translate.password_simple.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3marker.webp",

                    'translate.password_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3smap.webp",
                    'translate.password_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3mainsymbols.webp",
                    'translate.password_easy.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3mainitems.webp",
                    'translate.password_easy.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3marker.webp",
                    'translate.password_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3smap.webp",
                    'translate.password_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3guestsymbols.webp",
                    'translate.password_easy.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3guestitems.webp",
                    'translate.password_easy.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl3marker.webp",

                    'translate.poison_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5both.webp",
                    'translate.poison_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5main.webp",
                    'translate.poison_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5both.webp",
                    'translate.poison_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5guest.webp",

                    'translate.poison_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5both.webp",
                    'translate.poison_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5main.webp",
                    'translate.poison_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5both.webp",
                    'translate.poison_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/nl5guest.webp",
                    // dutch media ends ====
                    'translate.game.SIMPLE': 'Standaard',
                    'translate.game.EASY': 'Uitdagend',
                    'translate.game.MEDIUM': 'Geniaal',
                    'translate.game.HARD': 'Expert',

                    'translate.game.hint.solution.label': "Oplossing",
                    'translate.game.hint.explanation.label': "Uitleg",
                    'translate.game.hint.hints.label': "Hints",
                    'translate.game.hint.hint1.label': "Hint 1",
                    'translate.game.hint.hint2.label': "Hint 2",
                    'translate.game.puzzle.timer.watch_video': "Bekijk de volgende video:",

                    'translate.game.hint.explanation_minus_points.label': "Uitleg",
                    'translate.game.hint.hint1_minus_points.label': "Hint 1",
                    'translate.game.hint.hint2_minus_points.label': "Hint 2",

                    'translate.game.toxic_case.puzzle_name.intro1': "Intro 1",
                    'translate.game.toxic_case.puzzle_name.intro2': "Intro 2",
                    'translate.game.toxic_case.puzzle_name.deceased_persons': "Deceased persons",
                    'translate.game.toxic_case.puzzle_name.differences': "Differences",
                    'translate.game.toxic_case.puzzle_name.drone': "Drone",
                    'translate.game.toxic_case.puzzle_name.enclose': "Enclose",
                    'translate.game.toxic_case.puzzle_name.escape': "Escape",
                    'translate.game.toxic_case.puzzle_name.open_the_door': "Open the door",
                    'translate.game.toxic_case.puzzle_name.password': "Password",
                    'translate.game.toxic_case.puzzle_name.poison': "Poison",
                    'translate.game.toxic_case.puzzle_name.tools_and_wires': "Tools and wires",
                    'translate.game.toxic_case.puzzle_name.who_did_it': "Who did it",

                    'translate.game.messages.answers.waiting': "OK, ik weet wat ik moet doen. Laat {{partnerName}} weten dat we verder kunnen gaan.",
                    'translate.game.messages.answers.incorrectWhenOpponentCorrect': "{{partnerName}} heeft me ook iets geappt.",
                    'translate.game.messages.answers.bothIncorrect': "Ik heb ernaar gekeken, maar het lijkt niet te kloppen...",
                    'translate.game.messages.answers.opponentCorrect': "Jouw oplossing werkte niet, maar die van {{partnerName}} wel!",
                    'translate.game.messages.answers.diffAnswersImRightButWaiting': "Wat jij zegt klinkt logisch, maar wat {{partnerName}} appt niet. Help {{partnerName}} en geef me het teken als jullie het eens zijn.",

                    'translate.game.score.65': "Niet slecht! Jullie scoren beter dan 50% van alle spelers",
                    'translate.game.score.75': "Netjes! Jullie hebben een betere score dan 75% van alle spelers",
                    'translate.game.score.80': "Goede score! Jullie zitten in de top 20%!",
                    'translate.game.score.85': "Hele goede score! Jullie horen bij de beste 15% voor deze opdracht",
                    'translate.game.score.90': "Fantastische score! Jullie zitten bij de top 10% voor deze opdracht",
                    'translate.game.score.95': "Bijna perfect! Jullie zitten bij de top 5% voor deze opdracht!",
                    'translate.game.score.100': "Waanzinnig! Maar 1% van de spelers krijgt een score van 100 voor deze puzzel.",
                    'translate.game.score.140': "Niet slecht! Jullie scoren beter dan 50% van de spelers op deze moeilijkheidsgraad",
                    'translate.game.score.150': "Netjes! Jullie hebben een betere score dan 75% van alle spelers op deze moeilijkheidsgraad",
                    'translate.game.score.160': "Mooi resultaat! Jullie zitten bij de top 25% van de spelers die op deze moeilijkheidsgraad spelen",
                    'translate.game.score.170': "Goede score! Jullie zitten in de top 20 van de spelers die op Uitdagend spelen",
                    'translate.game.score.180': "Hele goede score! Jullie horen bij de beste 15% voor dit hoofdstuk op Uitdagend",
                    'translate.game.score.190': "Fantastische score! Jullie zitten bij de top 10% voor dit hoofdstuk op Uitdagend",
                    'translate.game.score.195': "Bijna perfect! Jullie zitten bij de top 5% voor deze puzzel! En dat op Uitdagend!",
                    'translate.game.score.200': "Waanzinnig! Jullie horen bij de allerbeste ter wereld voor deze opdracht!",
                    'translate.game.score.241': "Impressive! Better than 50% of players on 'crazy' difficulty",
                    'translate.game.score.250': "Nice! You scored better then 55% of players on 'crazy' difficulty level",
                    'translate.game.score.260': "Well done! You are in the top 25% for this challenge (on \"crazy\" level)",
                    'translate.game.score.270': "Good score: You two in the top 20% (for \"crazy\" difficulty)",
                    'translate.game.score.280': "Very good score! You are in the top 15% for this challenge (on \"crazy \"difficulty",
                    'translate.game.score.290': "Great score! You are in the top 10% for this challenge (on \"crazy\" difficulty)",
                    'translate.game.score.295': "You are the crazy geniuses within the crazy geniuses group! You are in the top 5%",
                    'translate.game.score.300': "Wow! OK, there are 2 options. You are crazy geniuses, or you are cheating. But we don't want to tell crazy geniuses that they are cheating, so well done!",

                    'translate.1_intro_simple.player.timer.message1': 'Hey player, maybe you need some help?',
                    'translate.1_intro_simple.player.timer.message2': 'It is hard huh? Keep up!',
                    'translate.1_intro_simple.player.timer.message3': 'Tick tack tick tack, maybe you need to use a hint?',
                    'translate.1_intro_simple.guest.timer.message1': 'Hey guest, maybe you need some help?',
                    'translate.1_intro_simple.player.hint.explanation': "Voor iedere opdracht is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en echt geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je medespeler hetzelfde 3-cijferige getal vinden. Klik hiervoor allebij op Hint 1.",
                    'translate.1_intro_simple.player.hint.hint2': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_simple.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_simple.player.hint.hint3': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",
                    'translate.1_intro_simple.guest.hint.explanation': "Voor iedere opdracht is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en echt geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_simple.guest.hint.hint2': "Het laatste cijfer is een 6. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_simple.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_simple.guest.hint.hint3': "Heeft je medespeler verteld wat het eerste cijfer is? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",

                    'translate.1_intro_simple.player.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_simple.player.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_simple.player.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie - A Toxic Case'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_simple.player.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_simple.guest.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_simple.guest.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_simple.guest.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar 'Introductie'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_simple.guest.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_easy.player.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_easy.player.hint.hint2': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_easy.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_easy.player.hint.hint3': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",
                    'translate.1_intro_easy.guest.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_easy.guest.hint.hint2': "Het laatste cijfer is een 6. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_easy.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_easy.guest.hint.hint3': "Heeft je medespeler verteld wat het eerste cijfer is? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",

                    'translate.1_intro_easy.player.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_easy.player.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_easy.player.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Vimeo (of Youtube), het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie' ? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_easy.player.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_easy.guest.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_easy.guest.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_easy.guest.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Vimeo (of Youtube), het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_easy.guest.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_medium.player.hint.explanation': "Voor alle opdrachten is een instructie beschikbaar. Dit is een hint die de puzzel uitlegt en kun je gebruiken als je geen idee hebt wat je moet doen. Voor deze intro 1 puzzel, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_medium.player.hint.hint1': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Met elkaar praten is cruciaal! De enige regel is dat je je scherm nooit aan de ander laat zien. Klik nu op Hint 2.",
                    'translate.1_intro_medium.player.hint.hint2': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn. Als je de oplossing bekijkt krijg je geen score.",
                    'translate.1_intro_medium.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_medium.guest.hint.explanation': "The game hasn't even started and you're already choosing higher difficulty? We like your confidence! But this is just practise, you we keep the challenges the same... Your last digit is a 6. Be sure to tell this to your partner. This game is all about talking to each other (as long as you don't show your screen). Now check hint 2.",
                    'translate.1_intro_medium.guest.hint.hint1': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Met elkaar praten is cruciaal! De enige regel is dat je je scherm nooit aan de ander laat zien. Klik nu op Hint 2.",
                    'translate.1_intro_medium.guest.hint.hint2': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn. Als je de oplossing bekijkt krijg je geen score.",
                    'translate.1_intro_medium.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_medium.player.message1.title': "Hi {{playerName}}, welkom bij Couch Clues. Je leert het spel door twee korte introductie opdrachten. Open Youtube op je tv (of op een ander scherm). Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1'. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_medium.player.message2.title': "Volg de instructies op het TV scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_medium.player.message2.error': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case'? En kijken jullie naar 'Intro 1'? Open dan het menu rechtsboven, open onder het kopje Hints eerst de Instructies, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_medium.player.message3.title': "Dat is goed! Pauzeer nu de video pauzeren en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",
                    'translate.1_intro_medium.guest.message1.title': "Hi {{playerName}}, welkom bij Couch Clues. Je leert het spel door twee korte introductie opdrachten. Open Youtube op je tv (of op een ander scherm). Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1'. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_medium.guest.message2.title': "Volg de instructies op het TV scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_medium.guest.message2.error': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case'? En kijken jullie naar 'Intro 1'? Open dan het menu rechtsboven, open onder het kopje Hints eerst de Instructies, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_medium.guest.message3.title': "Dat is goed! Pauzeer nu de video pauzeren en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_hard.player.hint.explanation': "All puzzles have an explanation hint. Explanation hints will explain the puzzle if you have no idea idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click Hint 1.",
                    'translate.1_intro_hard.player.hint.hint1': "The game hasn't even started and you're already choosing higher difficulty? We like your confidence! But this is just practise, you we keep the challenges the same... The first digit is number 9. Be sure to tell this to your partner. This game is all about talking to each other (as long as you don't show your screen). Now check hint 2.",
                    'translate.1_intro_hard.player.hint.hint2': "Did your partner tell you what the number started with? The second number is a 3. You could now solve the puzzle, or access the solution to be sure. Don't forget, this is just for practice!",
                    'translate.1_intro_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.1_intro_hard.guest.hint.explanation': "The game hasn't even started and you're already choosing crazy genius? Well... you must be crazy (and may be a genius). But for this practice challenge there is no difference in difficulty yet.... Your last digit is a 6. Be sure to tell this to your partner. This game is all about talking to each other (as long as you don't show your screen). Now check hint 2.",
                    'translate.1_intro_hard.guest.hint.hint1': "The game hasn't even started and you're already choosing higher difficulty? We like your confidence! But this is just practise, you we keep the challenges the same... The first digit is number 9. Be sure to tell this to your partner. This game is all about talking to each other (as long as you don't show your screen). Now check hint 2.",
                    'translate.1_intro_hard.guest.hint.hint2': "Did your partner tell you what the number started with? The second number is a 3. You could now solve the puzzle, or access the solution to be sure. Don't forget, this is just for practice!",
                    'translate.1_intro_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.1_intro_hard.player.message1.title': "Hi! Welcome to Couch Clues. You will learn the game by playing 2 quick intro challenges. Open Youtube on your TV (or any other screen).  Then Search for 'CouchClues' channel. Find Playlist 'A Toxic Case' and Play the first video 'Intro 1'.",
                    'translate.1_intro_hard.player.message2.title': "Follow all the instruction on your television screen. Your goal is to send a 3 digit code.",
                    'translate.1_intro_hard.player.message2.error': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel CouchClues and the Playlist A Toxic Case? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation hint, then hint 1 and then hint 2.",
                    'translate.1_intro_hard.player.message3.title': "Well done! Now you can pause the video and click the results button below.",
                    'translate.1_intro_hard.guest.message1.title': "Hi! Welcome to Couch Clues. You will learn the game by playing 2 quick intro challenges. Open Youtube on your TV (or any other screen).  Then Search for 'CouchClues' channel. Find Playlist 'A Toxic Case' and Play the first video 'Intro 1'.",
                    'translate.1_intro_hard.guest.message2.title': "Follow all the instruction on your television screen. Your goal is to send a 3 digit code.",
                    'translate.1_intro_hard.guest.message2.error': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel CouchClues and the Playlist A Toxic Case? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation hint, then hint 1 and then hint 2.",
                    'translate.1_intro_hard.guest.message3.title': "Well done! Now you can pause the video and click the results button below.",

                    'translate.2_intro_simple.player.option1': "Een",
                    'translate.2_intro_simple.player.option2': "Twee",
                    'translate.2_intro_simple.player.option3': "Drie",
                    'translate.2_intro_simple.player.option4': "Vier",
                    'translate.2_intro_simple.player.option5': "Vijf",
                    'translate.2_intro_simple.player.option6': "Zes",
                    'translate.2_intro_simple.player.option7': "Zeven",
                    'translate.2_intro_simple.player.option8': "Acht",
                    'translate.2_intro_simple.player.option9': "Negen",
                    'translate.2_intro_simple.player.option10': "Nul",
                    'translate.2_intro_simple.guest.option1': "Een",
                    'translate.2_intro_simple.guest.option2': "Twee",
                    'translate.2_intro_simple.guest.option3': "Drie",
                    'translate.2_intro_simple.guest.option4': "Vier",
                    'translate.2_intro_simple.guest.option5': "Vijf",
                    'translate.2_intro_simple.guest.option6': "Zes",
                    'translate.2_intro_simple.guest.option7': "Zeven",
                    'translate.2_intro_simple.guest.option8': "Acht",
                    'translate.2_intro_simple.guest.option9': "Negen",
                    'translate.2_intro_simple.guest.option10': "Nul",
                    'translate.2_intro_simple.timer.title': "Intro 2 - A Toxic Case",
                    'translate.2_intro_simple.timer.subtitle': "De oefenopdracht start over:",
                    'translate.2_intro_simple.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_simple.player.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_simple.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_simple.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_simple.guest.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_simple.guest.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_simple.guest.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_simple.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_simple.player.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_simple.player.message2.title': "Jullie doel is om een 4 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven.",
                    'translate.2_intro_simple.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_simple.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",
                    'translate.2_intro_simple.guest.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_simple.guest.message2.title': "Jullie doel is om een 4 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven.",
                    'translate.2_intro_simple.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_simple.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

                    'translate.2_intro_easy.player.option1': "Een",
                    'translate.2_intro_easy.player.option2': "Twee",
                    'translate.2_intro_easy.player.option3': "Drie",
                    'translate.2_intro_easy.player.option4': "Vier",
                    'translate.2_intro_easy.player.option5': "Vijf",
                    'translate.2_intro_easy.player.option6': "Zes",
                    'translate.2_intro_easy.player.option7': "Zeven",
                    'translate.2_intro_easy.player.option8': "Acht",
                    'translate.2_intro_easy.player.option9': "Negen",
                    'translate.2_intro_easy.player.option10': "Nul",
                    'translate.2_intro_easy.guest.option1': "Een",
                    'translate.2_intro_easy.guest.option2': "Twee",
                    'translate.2_intro_easy.guest.option3': "Drie",
                    'translate.2_intro_easy.guest.option4': "Vier",
                    'translate.2_intro_easy.guest.option5': "Vijf",
                    'translate.2_intro_easy.guest.option6': "Zes",
                    'translate.2_intro_easy.guest.option7': "Zeven",
                    'translate.2_intro_easy.guest.option8': "Acht",
                    'translate.2_intro_easy.guest.option9': "Negen",
                    'translate.2_intro_easy.guest.option10': "Nul",
                    'translate.2_intro_easy.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_easy.player.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_easy.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_easy.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_easy.guest.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_easy.guest.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_easy.guest.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_easy.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_easy.player.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_easy.player.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden.",
                    'translate.2_intro_easy.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_easy.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",
                    'translate.2_intro_easy.guest.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_easy.guest.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden.",
                    'translate.2_intro_easy.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_easy.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

                    'translate.2_intro_medium.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_medium.player.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_medium.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_medium.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_medium.guest.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_medium.guest.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_medium.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_medium.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_medium.player.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_medium.player.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden. Er is maar 1 spelregel, laat je scherm niet aan je medespeler zien!",
                    'translate.2_intro_medium.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_medium.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Vergeet niet dat je de moeilijkheidsgraad voor elke puzzel kan aanpassen. Veel plezier met 'A Toxic Case'!",
                    'translate.2_intro_medium.guest.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_medium.guest.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden. Er is maar 1 spelregel, laat je scherm niet aan je medespeler zien!",
                    'translate.2_intro_medium.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_medium.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Vergeet niet dat je de moeilijkheidsgraad voor elke puzzel kan aanpassen. Veel plezier met 'A Toxic Case'!",
                    'translate.2_intro_medium.player.option1': "Een",
                    'translate.2_intro_medium.player.option2': "Twee",
                    'translate.2_intro_medium.player.option3': "Drie",
                    'translate.2_intro_medium.player.option4': "Vier",
                    'translate.2_intro_medium.player.option5': "Vijf",
                    'translate.2_intro_medium.player.option6': "Zes",
                    'translate.2_intro_medium.player.option7': "Zeven",
                    'translate.2_intro_medium.player.option8': "Acht",
                    'translate.2_intro_medium.player.option9': "Negen",
                    'translate.2_intro_medium.player.option10': "Nul",
                    'translate.2_intro_medium.guest.option1': "Een",
                    'translate.2_intro_medium.guest.option2': "Twee",
                    'translate.2_intro_medium.guest.option3': "Drie",
                    'translate.2_intro_medium.guest.option4': "Vier",
                    'translate.2_intro_medium.guest.option5': "Vijf",
                    'translate.2_intro_medium.guest.option6': "Zes",
                    'translate.2_intro_medium.guest.option7': "Zeven",
                    'translate.2_intro_medium.guest.option8': "Acht",
                    'translate.2_intro_medium.guest.option9': "Negen",
                    'translate.2_intro_medium.guest.option10': "Nul",


                    'translate.2_intro_hard.player.option1': "Een",
                    'translate.2_intro_hard.player.option2': "Twee",
                    'translate.2_intro_hard.player.option3': "Drie",
                    'translate.2_intro_hard.player.option4': "Vier",
                    'translate.2_intro_hard.player.option5': "Vijf",
                    'translate.2_intro_hard.player.option6': "Zes",
                    'translate.2_intro_hard.player.option7': "Zeven",
                    'translate.2_intro_hard.player.option8': "Acht",
                    'translate.2_intro_hard.player.option9': "Negen",
                    'translate.2_intro_hard.player.option10': "Nul",
                    'translate.2_intro_hard.guest.option1': "Een",
                    'translate.2_intro_hard.guest.option2': "Twee",
                    'translate.2_intro_hard.guest.option3': "Drie",
                    'translate.2_intro_hard.guest.option4': "Vier",
                    'translate.2_intro_hard.guest.option5': "Vijf",
                    'translate.2_intro_hard.guest.option6': "Zes",
                    'translate.2_intro_hard.guest.option7': "Zeven",
                    'translate.2_intro_hard.guest.option8': "Acht",
                    'translate.2_intro_hard.guest.option9': "Negen",
                    'translate.2_intro_hard.guest.option10': "Nul",
                    'translate.2_intro_hard.player.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_hard.player.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_hard.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.2_intro_hard.guest.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_hard.guest.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_hard.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.2_intro_hard.message1.title': "Now it's time for a real practice puzzle. You will see some information on your screen. Read the information and then play video 2: Intro 2. Don't forget this game has only one rule: never show your screen to the other player",
                    'translate.2_intro_hard.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_hard.message3.title': "Find the four digit code. This is just a practice challenge, so don't hesitate to use hints!",
                    'translate.2_intro_hard.guest_message1.title': "Now it's time for a real practice puzzle. You will see some information on your screen. Read the information and then play video 2: Intro 2. Don't forget this game has only one rule: never show your screen to the other player",
                    'translate.2_intro_hard.guest_message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_hard.guest_message3.title': "Check your score. If you think this was a 100% no brainer, then we recommend changing your difficulty to hard for the real game. We never recommend setting it to crazy (that's why it's called crazy). Have fun with the real game!",

                    'translate.deceased_person_simple.timer.title': "2. Deceased persons - A Toxic Case",
                    'translate.deceased_person_simple.timer.subtitle': "De volgende opdracht start over:",
                    'translate.deceased_person_simple.player.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
                    'translate.deceased_person_simple.player.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
                    'translate.deceased_person_simple.guest.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
                    'translate.deceased_person_simple.guest.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',

                    'translate.deceased_person_simple.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
                    'translate.deceased_person_simple.player.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie).",
                    'translate.deceased_person_simple.player.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
                    'translate.deceased_person_simple.player.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",
                    'translate.deceased_person_simple.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
                    'translate.deceased_person_simple.guest.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie)..",
                    'translate.deceased_person_simple.guest.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
                    'translate.deceased_person_simple.guest.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",
                    'translate.deceased_person_simple.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.deceased_person_simple.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
                    'translate.deceased_person_simple.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.deceased_person_simple.message3.title': "Dank! Dat lijkt te kloppen met aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",
                    'translate.deceased_person_simple.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.deceased_person_simple.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
                    'translate.deceased_person_simple.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.deceased_person_simple.guest_message3.title': "Dank! Dat lijkt te kloppen met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

                    'translate.deceased_person_easy.timer.title': "2. Deceased persons - A Toxic Case",
                    'translate.deceased_person_easy.timer.subtitle': "De volgende opdracht start over:",
                    'translate.deceased_person_easy.player.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Het lijkt erop dat Charlie Yang en Joe Lopez niet vergiftigd zijn.',
                    'translate.deceased_person_easy.player.timer.message2': 'Zie net dat op de achterkant van de foto van Simon Carlson staat dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
                    'translate.deceased_person_easy.guest.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Het lijkt erop dat Charlie Yang en Joe Lopez niet vergiftigd zijn.',
                    'translate.deceased_person_easy.guest.timer.message2': 'Zie net dat op de achterkant van de foto van Simon Carlson staat dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
                    'translate.deceased_person_easy.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Focus je eerst op het leeftijdsverschil en wie er een hartaandoening hebben.",
                    'translate.deceased_person_easy.player.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
                    'translate.deceased_person_easy.player.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
                    'translate.deceased_person_easy.player.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",
                    'translate.deceased_person_easy.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Focus je eerst op het leeftijdsverschil en wie er een hartaandoening hebben.",
                    'translate.deceased_person_easy.guest.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
                    'translate.deceased_person_easy.guest.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
                    'translate.deceased_person_easy.guest.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",
                    'translate.deceased_person_easy.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.deceased_person_easy.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
                    'translate.deceased_person_easy.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.deceased_person_easy.message3.title': "Dat klopt met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop?",
                    'translate.deceased_person_easy.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.deceased_person_easy.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
                    'translate.deceased_person_easy.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.deceased_person_easy.guest_message3.title': "Dank! Dat lijkt te kloppen met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

                    'translate.deceased_person_medium.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd.",
                    'translate.deceased_person_medium.player.hint.hint1': "Richt je eerst op het vinden van de oudste drie mensen en de mensen met tattooages. Dan heb je de 6 mensen die medicatie gebruikten. Hoeveel van deze 6 personen hebben een bril? En wat betekent dit voor de andere personen met een bril?",
                    'translate.deceased_person_medium.player.hint.hint2': "Anna, Simon en Charlie hebben oorbellen en gebruiken medicatie. De mensen die medicatie gebruikten, maar niet een van de drie oudsten zijn, zijn niet vergiftigd.",
                    'translate.deceased_person_medium.player.hint.solution': "Simon en Sam. <br> De 3 oudste personen en de personen met tattoos gebruikten medicatie. Dit zijne Simon, Joe, Rahul, Justin, Charlie, Matthew. Matthew heeft een bril, dus alle anderen met een bril hebben een hartaandoening. Er blijven 3 mensen over die oorbellen hebben maar geen hartaandoening. Simon, Charlie en Anna gebruiken dus medicatie. Dit betekent dat Jim de enige persoon met blauwe ogen is die geen medicatie gebruikt. Hij kreeg een auto ongeluk. De mensen die vergiftid zijn liggen schelen 30 jaar in leeftijd en maar 1 van de twee vergiftigden gebruikt medicatie. Dit kan alleen las Sam de jongste van de twee is. Een van de twee heeft oorbellen, dus de andere moet Simon zijn.",
                    'translate.deceased_person_medium.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd.",
                    'translate.deceased_person_medium.guest.hint.hint1': "Richt je eerst op het vinden van de oudste drie mensen en de mensen met tattooages. Dan heb je de 6 mensen die medicatie gebruikten. Hoeveel van deze 6 personen hebben een bril? En wat betekent dit voor de andere personen met een bril?",
                    'translate.deceased_person_medium.guest.hint.hint2': "Anna, Simon en Charlie hebben oorbellen en gebruiken medicatie. De mensen die medicatie gebruikten, maar niet een van de drie oudsten zijn, zijn niet vergiftigd.",
                    'translate.deceased_person_medium.guest.hint.solution': "Simon en Sam. <br> De 3 oudste personen en de personen met tattoos gebruikten medicatie. Dit zijne Simon, Joe, Rahul, Justin, Charlie, Matthew. Matthew heeft een bril, dus alle anderen met een bril hebben een hartaandoening. Er blijven 3 mensen over die oorbellen hebben maar geen hartaandoening. Simon, Charlie en Anna gebruiken dus medicatie. Dit betekent dat Jim de enige persoon met blauwe ogen is die geen medicatie gebruikt. Hij kreeg een auto ongeluk. De mensen die vergiftid zijn liggen schelen 30 jaar in leeftijd en maar 1 van de twee vergiftigden gebruikt medicatie. Dit kan alleen las Sam de jongste van de twee is. Een van de twee heeft oorbellen, dus de andere moet Simon zijn.",
                    'translate.deceased_person_medium.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.deceased_person_medium.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
                    'translate.deceased_person_medium.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.deceased_person_medium.message3.title': "Dat klopt met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop?",
                    'translate.deceased_person_medium.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.deceased_person_medium.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.x",
                    'translate.deceased_person_medium.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.deceased_person_medium.guest_message3.title': "Dank! Dat klopt iig met zijn aantekeningen. Ik zal kijken of ik meer te weten kan komen.",

                    'translate.deceased_person_hard.player.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduct which 2 of them have been poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first who travels by train and who goes to work in the city center",
                    'translate.deceased_person_hard.player.hint.hint1': "Rahul Patel and Jim Booth are not one of the 2 people you are looking for",
                    'translate.deceased_person_hard.player.hint.hint2': "One of the 2 poisoned people is Simon Carlsen",
                    'translate.deceased_person_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.deceased_person_hard.guest.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduct which 2 of them have been poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first who travels by train and who goes to work in the city center",
                    'translate.deceased_person_hard.guest.hint.hint1': "Rahul Patel and Jim Booth are not one of the 2 people you are looking for",
                    'translate.deceased_person_hard.guest.hint.hint2': "One of the 2 poisoned people is Simon Carlsen",
                    'translate.deceased_person_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.deceased_person_hard.message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_hard.message2.title': "xxxx",
                    'translate.deceased_person_hard.message2.error': "I checked it, but I think this contradicts with some of his notes. Can you check again for me?",
                    'translate.deceased_person_hard.message3.title': "Let me know if you can find out which 2 people were poisoned. I will try to look around in the meantime.",
                    'translate.deceased_person_hard.guest_message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
                    'translate.deceased_person_hard.guest_message2.title': "xxxx",
                    'translate.deceased_person_hard.guest_message2.error': "I checked it, but I think this contradicts with some of his notes. Can you check again for me?",
                    'translate.deceased_person_hard.guest_message3.title': "OK, that could be possible. Now lets find out why he was looking into these two",

                    'translate.differences_simple.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_simple.player.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_simple.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
                    'translate.differences_simple.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_simple.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_simple.guest.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_simple.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
                    'translate.differences_simple.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_simple.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_simple.message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_simple.message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_simple.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_simple.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_simple.guest_message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_simple.guest_message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_simple.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",


                    'translate.differences_easy.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_easy.player.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_easy.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
                    'translate.differences_easy.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_easy.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_easy.guest.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_easy.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
                    'translate.differences_easy.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_easy.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_easy.message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_easy.message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_easy.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_easy.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_easy.guest_message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_easy.guest_message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_easy.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",

                    'translate.differences_medium.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_medium.player.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_medium.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
                    'translate.differences_medium.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_medium.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_medium.guest.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_medium.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
                    'translate.differences_medium.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_medium.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_medium.message2.title': "xxxxxx",
                    'translate.differences_medium.message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_medium.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_medium.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_medium.guest_message2.title': "xxxxxx",
                    'translate.differences_medium.guest_message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_medium.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",

                    'translate.differences_hard.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_hard.player.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_hard.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11. We have a grey wire left",
                    'translate.differences_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_hard.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.differences_hard.guest.hint.hint1': "Each player is missing 3 items.",
                    'translate.differences_hard.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77",
                    'translate.differences_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.differences_hard.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_hard.message2.title': "xxxxx",
                    'translate.differences_hard.message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_hard.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.differences_hard.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.differences_hard.guest_message2.title': "xxxxxx",
                    'translate.differences_hard.guest_message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.differences_hard.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",

                    'translate.drone_simple.timer.title': "4. Drone - A Toxic Case",
                    'translate.drone_simple.timer.subtitle': "De volgende opdracht start over:",
                    'translate.drone_simple.player.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.drone_simple.player.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.drone_simple.guest.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.drone_simple.guest.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.drone_simple.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
                    'translate.drone_simple.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.drone_simple.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.drone_simple.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.drone_simple.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
                    'translate.drone_simple.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.drone_simple.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.drone_simple.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.drone_simple.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou een schets van de kantoren en {{partnerName}} een schets van de laboratoria in dit gebied. Kijk naar zowel de gebouwen als de wegen.",
                    'translate.drone_simple.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
                    'translate.drone_simple.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
                    'translate.drone_simple.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",
                    'translate.drone_simple.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou een schets van de laboratoria en {{partnerName}} een schets van de kantoren in dit gebied. Kijk naar zowel de gebouwen als de wegen",
                    'translate.drone_simple.guest_message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
                    'translate.drone_simple.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
                    'translate.drone_simple.guest_message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

                    'translate.drone_easy.timer.title': "4. Drone - A Toxic Case",
                    'translate.drone_easy.timer.subtitle': "De volgende opdracht start over:",
                    'translate.drone_easy.player.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.drone_easy.player.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.drone_easy.guest.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.drone_easy.guest.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.drone_easy.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen. Elke speler heeft andere gebouwen op de kaart staan.",
                    'translate.drone_easy.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.drone_easy.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.drone_easy.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.drone_easy.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen. Elke speler heeft andere gebouwen op de kaart staan.",
                    'translate.drone_easy.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.drone_easy.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.drone_easy.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.drone_easy.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft schetsen van het gebied gemaakt, ik stuur ze door.",
                    'translate.drone_easy.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, dan kan ik een manier vinden om erlangs te komen.",
                    'translate.drone_easy.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
                    'translate.drone_easy.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",
                    'translate.drone_easy.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft schetsen van het gebied gemaakt, ik stuur ze door.",
                    'translate.drone_easy.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
                    'translate.drone_easy.guest_message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
                    'translate.drone_easy.guest_message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

                    'translate.drone_medium.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de gebouwen.",
                    'translate.drone_medium.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.drone_medium.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.drone_medium.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. In de 2e zone staan de gebouwen schuin tegenover elkaar, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen zien met vormen die je kan herkennen in zone S.",
                    'translate.drone_medium.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de gebouwen.",
                    'translate.drone_medium.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.drone_medium.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.drone_medium.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. In de 2e zone staan de gebouwen schuin tegenover elkaar, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.drone_medium.message1.title': "Dit gebied wordt bewaakt door een drone. Ik moet ongezien binnen zien te komen. Ik stuur je de dronebeelden en een schets die John van het gebied heeft gemaakt.",
                    'translate.drone_medium.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
                    'translate.drone_medium.message3.title': "Dat lijkt te kloppen. Volgens mij kan ik er precies tussendoor.",
                    'translate.drone_medium.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Ik moet ongezien binnen zien te komen. Ik stuur je de dronebeelden en een schets die John van het gebied heeft gemaakt.",
                    'translate.drone_medium.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
                    'translate.drone_medium.guest_message3.title': "Dat lijkt te kloppen. Volgens mij kan ik er precies tussendoor.",

                    'translate.drone_hard.player.hint.explanation': "The drone flies in 8 zones. Find the right order by looking at the shape of the buildings",
                    'translate.drone_hard.player.hint.hint1': "The first zone is T and the drone ends with Zone Y",
                    'translate.drone_hard.player.hint.hint2': "The zones that are not shown are E, P, Q and V",
                    'translate.drone_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.drone_hard.guest.hint.explanation': "The drone flies in 8 zones. Find the right order by looking at the shape of the buildings",
                    'translate.drone_hard.guest.hint.hint1': "The first zone is T and the drone ends with Zone Y",
                    'translate.drone_hard.guest.hint.hint2': "The zones that are not shown are E, P, Q and V",
                    'translate.drone_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.drone_hard.message1.title': "This area is patrolled by drones. I need to get in undetected. Look at the drone footage. I will send you a sketch John left of the area.",
                    'translate.drone_hard.message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again and let me know.",
                    'translate.drone_hard.message3.title': "Let me know the order of the zones that the drone sees, so I can find a way to sneak past them",
                    'translate.drone_hard.guest_message1.title': "This area is patrolled by drones. I need to get in undetected. Look at the drone footage. I will send you a sketch John left of the area.",
                    'translate.drone_hard.guest_message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again and let me know.",
                    'translate.drone_hard.guest_message3.title': "Thanks. I'm going to make a run for it.",

                    'translate.enclose_simple.timer.title': "7. Enclose - A Toxic Case",
                    'translate.enclose_simple.timer.subtitle': "De volgende opdracht start over:",
                    'translate.enclose_simple.player.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
                    'translate.enclose_simple.player.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',
                    'translate.enclose_simple.guest.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
                    'translate.enclose_simple.guest.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',
                    'translate.enclose_simple.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
                    'translate.enclose_simple.player.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
                    'translate.enclose_simple.player.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
                    'translate.enclose_simple.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.enclose_simple.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
                    'translate.enclose_simple.guest.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
                    'translate.enclose_simple.guest.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
                    'translate.enclose_simple.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.enclose_simple.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.enclose_simple.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
                    'translate.enclose_simple.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.enclose_simple.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",
                    'translate.enclose_simple.guest_message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.enclose_simple.guest_message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
                    'translate.enclose_simple.guest_message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.enclose_simple.guest_message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",

                    'translate.enclose_easy.timer.title': "7. Enclose - A Toxic Case",
                    'translate.enclose_easy.timer.subtitle': "De volgende opdracht start over:",
                    'translate.enclose_easy.player.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.',
                    'translate.enclose_easy.player.timer.message2': 'We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit volgens mij in de opslag rechtsboven. Ze zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar.',
                    'translate.enclose_easy.guest.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.',
                    'translate.enclose_easy.guest.timer.message2': 'We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit volgens mij in de opslag rechtsboven. Ze zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar.',
                    'translate.enclose_easy.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",
                    'translate.enclose_easy.player.hint.hint1': "Ze start in ruimte 270 en gaat daarna nog 7 andere ruimtes in. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.",
                    'translate.enclose_easy.player.hint.hint2': "We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Justin zit in het toilet wat vastzit aan kamer 281.",
                    'translate.enclose_easy.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.enclose_easy.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",
                    'translate.enclose_easy.guest.hint.hint1': "Ze start in ruimte 270 en gaat daarna nog 7 andere ruimtes in. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren. Op welke camera's zien we haar wel en waar zien we haar nooit?",
                    'translate.enclose_easy.guest.hint.hint2': "We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Justin zit in het toilet wat vastzit aan kamer 281.",
                    'translate.enclose_easy.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.enclose_easy.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker, en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.enclose_easy.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Als ik het goed heb gehoord komt ze in totaal in 8 ruimtes. We hebben toegang kunnen krijgen tot een beperkt aantal camera's. Als we weten waar die zijn weten we waar ze wel en niet langsloopt.",
                    'translate.enclose_easy.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Volgens mij start ze in 270. Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.enclose_easy.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",
                    'translate.enclose_easy.guest_message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.enclose_easy.guest_message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Als ik het goed heb gehoord komt ze in totaal in 8 ruimtes. We hebben toegang kunnen krijgen tot een beperkt aantal camera's. Als we weten waar die zijn weten we waar ze wel en niet langsloopt.",
                    'translate.enclose_easy.guest_message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Volgens mij start ze in 270. Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.enclose_easy.guest_message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",

                    'translate.enclose_medium.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst te zien op welke camera's Linda zichtbaar is en let goed op wat Justin en Jennifer zeggen. Wanneer horen ze iets en wanneer niet? Waar zitten ze en wat gebeurt er in de ruimtes waar ze aan grenzen?",
                    'translate.enclose_medium.player.hint.hint1': "Justin, de onderzoeker zit in het toilet naast ruimte 281. Hij hoort alleen iets in de kamer naast hem als Linda Steve is gepasseerd. Jennifer heeft de hele tijd niets gehoord, ook niet in de ruimtes naast haar. Op welke plek kan zij zitten?",
                    'translate.enclose_medium.player.hint.hint2': "Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Dit betekent dat Linda door ruimte 217 is gegaan en vanuit daar naar de gang met de bewegingssensor.",
                    'translate.enclose_medium.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan, ruimte 266. Op enig moment is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.enclose_medium.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst te zien op welke camera's Linda zichtbaar is en let goed op wat Justin en Jennifer zeggen. Wanneer horen ze iets en wanneer niet? Waar zitten ze en wat gebeurt er in de ruimtes waar ze aan grenzen?",
                    'translate.enclose_medium.guest.hint.hint1': "Justin, de onderzoeker zit in het toilet naast ruimte 281. Hij hoort alleen iets in de kamer naast hem als Linda Steve is gepasseerd. Jennifer heeft de hele tijd niets gehoord, ook niet in de ruimtes naast haar. Op welke plek kan zij zitten?",
                    'translate.enclose_medium.guest.hint.hint2': "Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Dit betekent dat Linda door ruimte 217 is gegaan en vanuit daar naar de gang met de bewegingssensor.",
                    'translate.enclose_medium.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan, ruimte 266. Op enig moment is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.enclose_medium.message1.title': "We moeten haar stoppen. De enige andere mensen op deze verdieping zijn Jennifer, een bewaker en Justin, een onderzoeker. Ik heb ze uitgelegd wat ze moeten doen. We moeten die bommen tegelijk uitschakelen, zonder dat Linda een van ons ziet.",
                    'translate.enclose_medium.message2.title': "Help me uit te zoeken welke route ze loopt. We moeten er zeker van zijn, we hebben maar 1 kans.",
                    'translate.enclose_medium.message2.error': "Dit klopt niet met hoe ik het in mijn hoofd heb. Kun je het controleren? We moeten er zeker van zijn.",
                    'translate.enclose_medium.message3.title': "Ik hoop dat het klopt. We moeten nu iets doen. <br><br> Bekijk eerst de volgende video (8. The End) voordat je op Resultaten klikt.",
                    'translate.enclose_medium.guest_message1.title': "We moeten haar stoppen. De enige andere mensen op deze verdieping zijn Jennifer, een bewaker en Justin, een onderzoeker. Ik heb ze uitgelegd wat ze moeten doen. We moeten die bommen tegelijk uitschakelen, zonder dat Linda een van ons ziet.",
                    'translate.enclose_medium.guest_message2.title': "Help me uit te zoeken welke route ze loopt. We moeten er zeker van zijn, we hebben maar 1 kans.",
                    'translate.enclose_medium.guest_message2.error': "Dit klopt niet met hoe ik het in mijn hoofd heb. Kun je het controleren? We moeten er zeker van zijn.",
                    'translate.enclose_medium.guest_message3.title': "Ik hoop dat het klopt. We moeten nu iets doen. <br><br> Bekijk eerst de volgende video (8. The End) voordat je op Resultaten klikt.",

                    'translate.enclose_hard.player.hint.explanation': "H. Your goal is to deduct the route the Linda is taking. Look at where the cameras are located and focus first on finding where Jennifer and the Researcher are on the map. When do they hear something and when not? What does this mean for where they are located?",
                    'translate.enclose_hard.player.hint.hint1': "H. The researcher is in the toilet next to breakout room 231. He didn't hear anything, only at the end. If he was in the other toilet he would have heard Linda in the basement. In which location is Jennifer?",
                    'translate.enclose_hard.player.hint.hint2': "H. Linda is located in the closet in the top right corner. She says she didn't hear anything, not even in the rooms next to her. This means that Linda went through breakroom 217 and from there to the hallway with motion detector",
                    'translate.enclose_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.enclose_hard.guest.hint.explanation': "H. Your goal is to deduct the route the Linda is taking. Look at where the cameras are located and focus first on finding where Jennifer and the Researcher are on the map. When do they hear something and when not? What does this mean for where they are located?",
                    'translate.enclose_hard.guest.hint.hint1': "H. The researcher is in the toilet next to breakout room 231. He didn't hear anything, only at the end. If he was in the other toilet he would have heard Linda in the basement. In which location is Jennifer?",
                    'translate.enclose_hard.guest.hint.hint2': "H. Linda is located in the closet in the top right corner. She says she didn't hear anything, not even in the rooms next to her. This means that Linda went through breakroom 217 and from there to the hallway with motion detector",
                    'translate.enclose_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.enclose_hard.message1.title': "We have to stop the bombs before she can detonate them. This means we have to stop them at the same time, without her seeing one of us in the meantime. There are two people present in the building who can help us. Jennifer, head of the lab and one of her researchers.",
                    'translate.enclose_hard.message2.error': "If we do it like that she will see one of us before we have a chance to diffuse the bomb. We need another plan",
                    'translate.enclose_hard.message3.title': "We need to find out the route that Linda takes so we know when to move.",
                    'translate.enclose_hard.guest_message1.title': "We have to stop the bombs before she can detonate them. This means we have to stop them at the same time, without her seeing one of us in the meantime. There are two people present in the building who can help us. Jennifer, head of the lab and one of her researchers.",
                    'translate.enclose_hard.guest_message2.error': "If we do it like that she will see one of us before we have a chance to diffuse the bomb. We need another plan",
                    'translate.enclose_hard.guest_message3.title': "We have one shot. Let's do this. Play the next video before pressing results",

                    'translate.escape_simple.player.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_simple.player.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_simple.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_simple.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_simple.guest.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_simple.guest.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_simple.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_simple.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_simple.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_simple.message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_simple.message2.error': "Wait. Were these the right doors?",
                    'translate.escape_simple.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_simple.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_simple.guest_message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_simple.guest_message2.error': "Wait. Were these the right doors?",
                    'translate.escape_simple.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",


                    'translate.escape_easy.player.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_easy.player.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_easy.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_easy.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_easy.guest.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_easy.guest.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_easy.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_easy.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_easy.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_easy.message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_easy.message2.error': "Wait. Were these the right doors?",
                    'translate.escape_easy.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_easy.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_easy.guest_message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_easy.guest_message2.error': "Wait. Were these the right doors?",
                    'translate.escape_easy.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",

                    'translate.escape_medium.player.hint.explanation': "M. You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_medium.player.hint.hint1': "M. The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_medium.player.hint.hint2': "M. He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_medium.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_medium.guest.hint.explanation': "M. You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_medium.guest.hint.hint1': "M. The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_medium.guest.hint.hint2': "M. He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_medium.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_medium.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_medium.message2.title': "xxxxx",
                    'translate.escape_medium.message2.error': "Wait. Were these the right doors?",
                    'translate.escape_medium.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_medium.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_medium.guest_message2.title': "xxxx",
                    'translate.escape_medium.guest_message2.error': "Wait. Were these the right doors?",
                    'translate.escape_medium.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",

                    'translate.escape_hard.player.hint.explanation': "H. You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_hard.player.hint.hint1': "H. The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_hard.player.hint.hint2': "H. He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_hard.guest.hint.explanation': "H. You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.escape_hard.guest.hint.hint1': "H. The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.escape_hard.guest.hint.hint2': "H. He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.escape_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.escape_hard.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_hard.message2.title': "xxxxx",
                    'translate.escape_hard.message2.error': "Wait. Were these the right doors?",
                    'translate.escape_hard.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.escape_hard.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.escape_hard.guest_message2.title': "xxxx",
                    'translate.escape_hard.guest_message2.error': "Wait. Were these the right doors?",
                    'translate.escape_hard.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",

                    'translate.open_the_door_simple.timer.title': "1. Open the Door - A Toxic Case",
                    'translate.open_the_door_simple.timer.subtitle': "The volgende opdracht start over:",
                    'translate.open_the_door_simple.player.timer.message1': 'Misschien heb je dit al gezien, maar op de beelden zie ik hem elke dag dezelfde vier dingen doen. Is er een verband tussen de volgorde van de dingen die hij doet en de code?',
                    'translate.open_the_door_simple.player.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.open_the_door_simple.player.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
                    'translate.open_the_door_simple.guest.timer.message1': 'Misschien heb je dit al gezien, maar op de beelden zie ik hem elke dag dezelfde vier dingen doen. Is er een verband tussen de volgorde van de dingen die hij doet en de code?',
                    'translate.open_the_door_simple.guest.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.open_the_door_simple.guest.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',

                    'translate.open_the_door_simple.player.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
                    'translate.open_the_door_simple.player.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
                    'translate.open_the_door_simple.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
                    'translate.open_the_door_simple.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.open_the_door_simple.guest.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
                    'translate.open_the_door_simple.guest.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
                    'translate.open_the_door_simple.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
                    'translate.open_the_door_simple.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.open_the_door_simple.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.open_the_door_simple.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.open_the_door_simple.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.open_the_door_simple.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",
                    'translate.open_the_door_simple.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.open_the_door_simple.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.open_the_door_simple.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.open_the_door_simple.guest_message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

                    'translate.open_the_door_easy.timer.title': "1. Open the Door - A Toxic Case",
                    'translate.open_the_door_easy.timer.subtitle': "De volgende opdracht start over:",
                    'translate.open_the_door_easy.player.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
                    'translate.open_the_door_easy.player.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.open_the_door_easy.player.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
                    'translate.open_the_door_easy.guest.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
                    'translate.open_the_door_easy.guest.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.open_the_door_easy.guest.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
                    'translate.open_the_door_easy.player.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",
                    'translate.open_the_door_easy.player.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
                    'translate.open_the_door_easy.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
                    'translate.open_the_door_easy.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.open_the_door_easy.guest.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",
                    'translate.open_the_door_easy.guest.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
                    'translate.open_the_door_easy.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
                    'translate.open_the_door_easy.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.open_the_door_easy.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.open_the_door_easy.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.open_the_door_easy.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.open_the_door_easy.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",
                    'translate.open_the_door_easy.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.open_the_door_easy.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.open_the_door_easy.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.open_the_door_easy.guest_message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

                    'translate.open_the_door_medium.player.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen.",
                    'translate.open_the_door_medium.player.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
                    'translate.open_the_door_medium.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
                    'translate.open_the_door_medium.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.open_the_door_medium.guest.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen.",
                    'translate.open_the_door_medium.guest.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
                    'translate.open_the_door_medium.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
                    'translate.open_the_door_medium.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.open_the_door_medium.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.open_the_door_medium.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is? John weet dat ik zijn bewakingsbeelden kan bekijken, dus misschien is er een verband tussen die beelden en de codes die hij gebruikt?",
                    'translate.open_the_door_medium.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee?",
                    'translate.open_the_door_medium.message3.title': "Dat is de juiste code, bedankt. Ik ga naar binnen.",
                    'translate.open_the_door_medium.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.open_the_door_medium.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is? John weet dat ik zijn bewakingsbeelden kan bekijken, dus misschien is er een verband tussen die beelden en de codes die hij gebruikt?",
                    'translate.open_the_door_medium.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee?",
                    'translate.open_the_door_medium.guest_message3.title': "Dat is de juiste code, bedankt. Ik ga naar binnen.",

                    'translate.open_the_door_hard.player.hint.explanation': "You need to type in a 4 digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage. Do you see a pattern? And can you connect this to the door codes?",
                    'translate.open_the_door_hard.player.hint.hint1': "H. The second action on Friday is pushing the chair. That is the third action on Saturday and the fourth on Sunday.",
                    'translate.open_the_door_hard.player.hint.hint2': "What are the times on the different days?",
                    'translate.open_the_door_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.open_the_door_hard.guest.hint.explanation': "You need to type in a 4 digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage. Do you see a pattern? And can you connect this to the door codes?",
                    'translate.open_the_door_hard.guest.hint.hint1': "H. The second action on Friday is pushing the chair. That is the third action on Saturday and the fourth on Sunday.",
                    'translate.open_the_door_hard.guest.hint.hint2': "What are the times on the different days?",
                    'translate.open_the_door_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.open_the_door_hard.message1.title': "We need to get in. Please help me. I have found some security footage of John's door from the last couple of days. I'll send you his texts as well.",
                    'translate.open_the_door_hard.message2.title': "xxxxx",
                    'translate.open_the_door_hard.message2.error': "The door doesn't open. Can you check again for me?",
                    'translate.open_the_door_hard.message3.title': "I need a code to open his door. Can you find out what today's code would be?",
                    'translate.open_the_door_hard.guest_message1.title': "We need to get in. Please help me. I have found some security footage of John's door from the last couple of days. I'll send you his texts as well.",
                    'translate.open_the_door_hard.guest_message2.title': "xxxxx",
                    'translate.open_the_door_hard.guest_message2.error': "The door doesn't open. Can you check again for me?",
                    'translate.open_the_door_hard.guest_message3.title': "Thanks. Thát's the right code, I can open the door now.",

                    'translate.password_simple.timer.title': "3. Password - A Toxic Case",
                    'translate.password_simple.timer.subtitle': "De volgende opdracht start over:",
                    'translate.password_simple.player.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
                    'translate.password_simple.player.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
                    'translate.password_simple.player.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',
                    'translate.password_simple.guest.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
                    'translate.password_simple.guest.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
                    'translate.password_simple.guest.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',
                    'translate.password_simple.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.password_simple.player.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
                    'translate.password_simple.player.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.password_simple.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.password_simple.guest.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.password_simple.guest.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
                    'translate.password_simple.guest.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.password_simple.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.password_simple.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.password_simple.message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
                    'translate.password_simple.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.password_simple.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",
                    'translate.password_simple.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.password_simple.guest_message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
                    'translate.password_simple.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.password_simple.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",

                    'translate.password_easy.timer.title': "3. Password - A Toxic Case",
                    'translate.password_easy.timer.subtitle': "De volgende opdracht start over:",
                    'translate.password_easy.player.timer.message1': 'Volgens mij heb ik iets gevonden. Hij heeft 5 markers laten liggen, allemaal in een hele specifieke positie.',
                    'translate.password_easy.player.timer.message2': 'Ik heb een letter van het woord gevonden. Een van de markers wijst naar het boek. Het boek ligt op positie X in de kamer. De vierde letter van boek is "k", dus het woord begint met een "k".',
                    'translate.password_easy.guest.timer.message1': 'Volgens mij heb ik iets gevonden. Hij heeft 5 markers laten liggen, allemaal in een hele specifieke positie.',
                    'translate.password_easy.guest.timer.message2': 'Ik heb een letter van het woord gevonden. Een van de markers wijst naar het boek. Het boek ligt op positie X in de kamer. De vierde letter van boek is "k", dus het woord begint met een "k".',
                    'translate.password_easy.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.password_easy.player.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
                    'translate.password_easy.player.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.password_easy.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.password_easy.guest.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.password_easy.guest.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
                    'translate.password_easy.guest.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.password_easy.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.password_easy.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.password_easy.message2.title': "Ik stuur de notities naar jullie door. Volgens mij zoeken we een wachtwoord van 5 letters.",
                    'translate.password_easy.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.password_easy.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",
                    'translate.password_easy.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.password_easy.guest_message2.title': "Ik stuur de notities naar jullie door. Volgens mij zoeken we een wachtwoord van 5 letters.",
                    'translate.password_easy.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.password_easy.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",

                    'translate.password_medium.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen en van elk voorwerp 1 letter gebruiken.",
                    'translate.password_medium.player.hint.hint1': "Zoek de markeerstiften.",
                    'translate.password_medium.player.hint.hint2': "Een van de markeerstiften wijst naar een vaas. Waar ligt die? De tweede letter van het wachtwoord is 'a'.",
                    'translate.password_medium.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de getallen 1 tot 5 geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.password_medium.guest.hint.explanation': "We are looking for a 5 letter word. To find it you need to figure which words are hidden in the room and use the underlined letters. The order is shown by the second note.",
                    'translate.password_medium.guest.hint.hint1': "Zoek de markeerstiften.",
                    'translate.password_medium.guest.hint.hint2': "Een van de markeerstiften wijst naar een vaas. Waar ligt die? De tweede letter van het wachtwoord is 'a'.",
                    'translate.password_medium.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de getallen 1 tot 5 geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.password_medium.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notitities gevonden, ik stuur ze door. Verder is het hier een bende.",
                    'translate.password_medium.message2.title': "Kunnen jullie een wachtwoord achterhalen?",
                    'translate.password_medium.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.password_medium.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was.",
                    'translate.password_medium.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notitities gevonden, ik stuur ze door. Verder is het hier een bende.",
                    'translate.password_medium.guest_message2.title': "Kunnen jullie een wachtwoord achterhalen?",
                    'translate.password_medium.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.password_medium.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was.",

                    'translate.password_hard.player.hint.explanation': "We are looking for a 5 letter word. To find it you need to figure which words are hidden in the room and use the underlined letters. The order is shown by the second note.",
                    'translate.password_hard.player.hint.hint1': "Is there an item which you see often?",
                    'translate.password_hard.player.hint.hint2': "The markers points at certain items. Use the words from these items to find the password. It starts with A..",
                    'translate.password_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.password_hard.guest.hint.explanation': "We are looking for a 5 letter word. To find it you need to figure which words are hidden in the room and use the underlined letters. The order is shown by the second note.",
                    'translate.password_hard.guest.hint.hint1': "Is there an item which you see often?",
                    'translate.password_hard.guest.hint.hint2': "The markers points at certain items. Use the words from these items to find the password. It starts with A..",
                    'translate.password_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.password_hard.message1.title': "Can't login. I need his password. He left this note for me, but it just doesn't make any sense.",
                    'translate.password_hard.message2.title': "xxxx",
                    'translate.password_hard.message2.error': "Wrong password. We have to try another.",
                    'translate.password_hard.message3.title': "Can you send me the password?",
                    'translate.password_hard.guest_message1.title': "Can't login. I need his password. He left this note for me, but it just doesn't make any sense.",
                    'translate.password_hard.guest_message2.title': "xxxx",
                    'translate.password_hard.guest_message2.error': "Wrong password. We have to try another.",
                    'translate.password_hard.guest_message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",

                    'translate.poison_simple.timer.title': "5. Poison - A Toxic Case",
                    'translate.poison_simple.timer.subtitle': "De volgende opdracht start over:",
                    'translate.poison_simple.player.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.poison_simple.player.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.poison_simple.guest.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.poison_simple.guest.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.poison_simple.player.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
                    'translate.poison_simple.player.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
                    'translate.poison_simple.player.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
                    'translate.poison_simple.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.poison_simple.guest.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
                    'translate.poison_simple.guest.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
                    'translate.poison_simple.guest.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
                    'translate.poison_simple.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.poison_simple.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.poison_simple.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.poison_simple.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.poison_simple.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
                    'translate.poison_simple.guest_message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.poison_simple.guest_message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.poison_simple.guest_message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.poison_simple.guest_message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

                    'translate.poison_easy.timer.title': "5. Poison - A Toxic Case",
                    'translate.poison_easy.timer.subtitle': "The volgende opdracht start over:",
                    'translate.poison_easy.player.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.poison_easy.player.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.poison_easy.guest.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.poison_easy.guest.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.poison_easy.player.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
                    'translate.poison_easy.player.hint.hint1': "Zijn pupillen hebben een gebruikelijke grootte. Maar hoe is hij in contact gekomen met dit gif?",
                    'translate.poison_easy.player.hint.hint2': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien. Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen.",
                    'translate.poison_easy.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.poison_easy.guest.hint.explanation': "Het doel is om het nummer van het juiste vergif door te sturen. Het vergif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Alle aanwijzingen zijn in de video te zien of daaruit af te leiden.",
                    'translate.poison_easy.guest.hint.hint1': "Zijn pupillen hebben een gebruikelijke grootte. Maar hoe is hij in contact gekomen met dit gif?",
                    'translate.poison_easy.guest.hint.hint2': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien. Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen.",
                    'translate.poison_easy.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.poison_easy.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.poison_easy.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.poison_easy.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.poison_easy.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
                    'translate.poison_easy.guest_message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.poison_easy.guest_message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.poison_easy.guest_message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.poison_easy.guest_message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

                    'translate.poison_medium.player.hint.explanation': "Het doel is om het nummer van het juiste vergif door te sturen. Het vergif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Alle aanwijzingen zijn in de video te zien of daaruit af te leiden.",
                    'translate.poison_medium.player.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden.",
                    'translate.poison_medium.player.hint.hint2': "Hij is aan het zweten, wat een teken is van stress. De grootte van zijn pupillen is gebruikelijk. Welke informatie geeft het koffiekopje?",
                    'translate.poison_medium.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John kwam in contact met het gif via zijn mond (koffiekopje) en was aan het zweten (een teken van stress, dus het vergif werkte niet direct of pas na dagen). Hij heeft zijn vingers in een vuist (dus geen nierfalen) en er is niets ongebruikelijks aan zijn pupillen te zien. Voor alle overgebleven opties voor een herseninfarct is de huid de plek van contact, dus het moet synthetisch zijn en geen herseninfarct. Hij was aan het kwijlen, maar de X en C types met hartfalen zijn gemaakt van kruiden, dus kan het ook geen hartfalen zijn. Dus blijft verstikking over.",
                    'translate.poison_medium.guest.hint.explanation': "Het doel is om het nummer van het juiste vergif door te sturen. Het vergif heeft 5 verschillende eigenschappe (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Alle aanwijzingen zijn in de video te zien of daaruit af te leiden.",
                    'translate.poison_medium.guest.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden.",
                    'translate.poison_medium.guest.hint.hint2': "Hij is aan het zweten, wat een teken is van stress. De grootte van zijn pupillen is gebruikelijk. Welke informatie geeft het koffiekopje?",
                    'translate.poison_medium.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John kwam in contact met het gif via zijn mond (koffiekopje) en was aan het zweten (een teken van stress, dus het vergif werkte niet direct of pas na dagen). Hij heeft zijn vingers in een vuist (dus geen nierfalen) en er is niets ongebruikelijks aan zijn pupillen te zien. Voor alle overgebleven opties voor een herseninfarct is de huid de plek van contact, dus het moet synthetisch zijn en geen herseninfarct. Hij was aan het kwijlen, maar de X en C types met hartfalen zijn gemaakt van kruiden, dus kan het ook geen hartfalen zijn. Dus blijft verstikking over.",
                    'translate.poison_medium.message1.title': "Hij is dood. Geen duidelijke verwondingen, dus ik vermoed dat hij is vergiftigd. Ik stuur jullie meer informatie over verschillende gifsoorten.",
                    'translate.poison_medium.message2.error': "Dat gif past niet helemaal bij wat we hier zien. Is er nog een alternatief?",
                    'translate.poison_medium.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
                    'translate.poison_medium.message2.title': "Kunnen jullie uitzoeken of er een vergif bestaat dat past bij deze situatie?",

                    'translate.poison_hard.player.hint.explanation': "You need to find the right poison. The poison has six different attributes (the six columns in the table). Both players have different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
                    'translate.poison_hard.player.hint.hint1': "His pupils are narrowed. You need to find 4 clues in the video",
                    'translate.poison_hard.player.hint.hint2': "Look closely at the coffee cup, the sweat on his forehead, his fist, his drooling and his narrowed pupils.",
                    'translate.poison_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.poison_hard.guest.hint.explanation': "You need to find the right poison. The poison has six different attributes (the six columns in the table). Both players have different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
                    'translate.poison_hard.guest.hint.hint1': "His pupils are narrowed. You need to find 4 clues in the video",
                    'translate.poison_hard.guest.hint.hint2': "Look closely at the coffee cup, the sweat on his forehead, his fist, his drooling and his narrowed pupils.",
                    'translate.poison_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.poison_hard.message1.title': "He is dead. No blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
                    'translate.poison_hard.message2.title': "Can you tell me which poison was used to kill John?",
                    'translate.poison_hard.message2.error': "That poison doesn't match what I'm seeing in the crime scene. We're missing something...",
                    'translate.poison_hard.message3.title': "This matched all his symptoms. I think it is the same poisoned that killed the other two people. But it's not an off the shelf poison, must be a combination of chemicals that have this effect.",

                    'translate.tools_and_wires_simple.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_simple.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_simple.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_simple.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_simple.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_simple.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_simple.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_simple.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_simple.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.tools_and_wires_simple.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
                    'translate.tools_and_wires_simple.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
                    'translate.tools_and_wires_simple.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",


                    'translate.tools_and_wires_easy.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_easy.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_easy.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_easy.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_easy.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_easy.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_easy.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_easy.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_easy.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.tools_and_wires_easy.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
                    'translate.tools_and_wires_easy.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
                    'translate.tools_and_wires_easy.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",

                    'translate.tools_and_wires_medium.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_medium.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_medium.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_medium.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_medium.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_medium.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_medium.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_medium.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_medium.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.tools_and_wires_medium.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
                    'translate.tools_and_wires_medium.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
                    'translate.tools_and_wires_medium.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",

                    'translate.tools_and_wires_hard.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_hard.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_hard.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_hard.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.tools_and_wires_hard.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.tools_and_wires_hard.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.tools_and_wires_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.tools_and_wires_hard.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.tools_and_wires_hard.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
                    'translate.tools_and_wires_hard.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
                    'translate.tools_and_wires_hard.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",

                    'translate.who_did_it_simple.timer.title': "6. Who did it - A Toxic Case",
                    'translate.who_did_it_simple.timer.subtitle': "De volgende opdracht start over:",
                    'translate.who_did_it_simple.player.timer.message1': '{{playerName}}, kun jij kijken naar het aantal kopjes aan het begin en eind van elke video? Ik zal ook proberen om wat aantekeningen te maken.',
                    'translate.who_did_it_simple.player.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.who_did_it_simple.player.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.who_did_it_simple.guest.timer.message1': '{{playerName}}, kun jij kijken of er rechts in beeld een deurmat aan het begin en eind van elke video ligt? Ik zal ook proberen om wat aantekeningen te maken.',
                    'translate.who_did_it_simple.guest.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.who_did_it_simple.guest.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.who_did_it_simple.player.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat die rechts in beeld op de grond ligt en laat je medespeler letten op de koffiekopjes. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.who_did_it_simple.player.hint.hint1': "Nummer 31 eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.who_did_it_simple.player.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.who_did_it_simple.player.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.who_did_it_simple.guest.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de koffiekopjes en laat je medespeler letten op de deurmat die rechts op de grond ligt. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.who_did_it_simple.guest.hint.hint1': "Nummer 31 eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.who_did_it_simple.guest.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.who_did_it_simple.guest.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.who_did_it_simple.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.who_did_it_simple.message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
                    'translate.who_did_it_simple.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.who_did_it_simple.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
                    'translate.who_did_it_simple.guest_message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.who_did_it_simple.guest_message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
                    'translate.who_did_it_simple.guest_message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.who_did_it_simple.guest_message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",

                    'translate.who_did_it_easy.timer.title': "6. Who did it - A Toxic Case",
                    'translate.who_did_it_easy.timer.subtitle': "De volgende opdracht start over:",
                    'translate.who_did_it_easy.player.timer.message1': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. De eerste video begint zonder kopjes op het aanrecht en met een deurmat rechts in beeld. Aan het einde van deze video staat er 1 kopje op het aanrecht en is de deurmat weg.',
                    'translate.who_did_it_easy.player.timer.message2': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.who_did_it_easy.guest.timer.message1': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. De eerste video begint zonder kopjes op het aanrecht en met een deurmat rechts in beeld. Aan het einde van deze video staat er 1 kopje op het aanrecht en is de deurmat weg.',
                    'translate.who_did_it_easy.guest.timer.message2': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.who_did_it_easy.player.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat rechts in beeld en laat je medespeler letten op de koffiekopjes. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.who_did_it_easy.player.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er een kopje en is de deurmat weg.",
                    'translate.who_did_it_easy.player.hint.hint2': "De tweede video start met 1 koffiekopje en zonder mat en eindigt met 2 kopjes en een mat. De derde video is 22. Aan het begin van deze video staan er 2 kopjes en een deurmat en aan het einde staan er drie kopjes en een mat op de grond",
                    'translate.who_did_it_easy.player.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.who_did_it_easy.guest.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de koffiekopjes en laat je medespeler letten op de deurmat rechts in beeld. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.who_did_it_easy.guest.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er 1 kopje en is de deurmat weg.",
                    'translate.who_did_it_easy.guest.hint.hint2': "De tweede video begint met 1 koffiekopje en zonder deurmat en eindigt met 2 kopjes en een deurmat. De derde video is 22. Aan het begin van deze video staan er 2 kopjes en een deurmat en aan het einde staan er drie kopjes en een mat op de grond.",
                    'translate.who_did_it_easy.guest.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.who_did_it_easy.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes.",
                    'translate.who_did_it_easy.message2.title': "Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.who_did_it_easy.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.who_did_it_easy.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
                    'translate.who_did_it_easy.guest_message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes.",
                    'translate.who_did_it_easy.guest_message2.title': "Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.who_did_it_easy.guest_message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.who_did_it_easy.guest_message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",

                    'translate.who_did_it_medium.player.hint.explanation': "Probeer eerst de zes video's in de juiste volgorde te zetten. Kijk goed naar de situatie aan het begin en aan het eind van elke video. Als je de volgorde weet, kun je de juiste namen aan de juiste opnames koppelen.",
                    'translate.who_did_it_medium.player.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Let op de kopjes en de mat om de volgorde te bepalen.",
                    'translate.who_did_it_medium.player.hint.hint2': "De volgorde van de beelden is 31831-48721-21487-17517-98712-17756. Twee mensen gebruiken een kopje dat al eerder op het aanrecht was geplaatst door iemand anders.",
                    'translate.who_did_it_medium.player.hint.solution': "The volgorde is Bruce, George, Evan, David, Henry en Linda. <br> Als je naar de koffiekopjes op het aanrecht kijkt en de mat rechts zie je dat maar 1 volgorde mogelijk is. I31831 (start: 0 kopjes en een mat, eind: 1 kopje en geen mat).  48721 (start: zie einde 31831, eind: 2 kopjes en een mat). 21487 (einde: 3 kopjes en een mat). 17517 (einde 2 kopjes en geen mat). 98712 (einde: 1 kopje en een mat). 17756: (einde: 1 kopje en een mat). <br><br> 98712 gebruikt het kopje van 21487 en drinkt water, dus 21487 moet Evan zijn. 17517 (David) gebruikt het kopje van 31831 (Bruce) om koffie te maken. George is de enige die zijn kopje schoonspoelt voordat hij het terugzet. Henry (drinkt water) en Linda blijven over.",
                    'translate.who_did_it_medium.guest.hint.explanation': "Probeer eerst de zes video's in de juiste volgorde te zetten. Kijk goed naar de situatie aan het begin en aan het eind van elke video. Als je de volgorde weet, kun je de juiste namen aan de juiste opnames koppelen.",
                    'translate.who_did_it_medium.guest.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Let op de kopjes en de mat om de volgorde te bepalen.",
                    'translate.who_did_it_medium.guest.hint.hint2': "De volgorde van de beelden is 31831-48721-21487-17517-98712-17756. Twee mensen gebruiken een kopje dat al eerder op het aanrecht was geplaatst door iemand anders.",
                    'translate.who_did_it_medium.guest.hint.solution': "The volgorde is Bruce, George, Evan, David, Henry en Linda. <br> Als je naar de koffiekopjes op het aanrecht kijkt en de mat rechts zie je dat maar 1 volgorde mogelijk is. I31831 (start: 0 kopjes en een mat, eind: 1 kopje en geen mat).  48721 (start: zie einde 31831, eind: 2 kopjes en een mat). 21487 (einde: 3 kopjes en een mat). 17517 (einde 2 kopjes en geen mat). 98712 (einde: 1 kopje en een mat). 17756: (einde: 1 kopje en een mat). <br><br> 98712 gebruikt het kopje van 21487 en drinkt water, dus 21487 moet Evan zijn. 17517 (David) gebruikt het kopje van 31831 (Bruce) om koffie te maken. George is de enige die zijn kopje schoonspoelt voordat hij het terugzet. Henry (drinkt water) en Linda blijven over.",
                    'translate.who_did_it_medium.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Ik heb ook wat briefjes gevonden van de mensen die hier werken.",
                    'translate.who_did_it_medium.message2.title': "Als we kunnen achterhalen wie wanneer koffie heeft gezet, vinden misschien de persoon die John heeft vergiftigd.",
                    'translate.who_did_it_medium.message2.error': "Deze volgorde klopt niet volgens mij. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.who_did_it_medium.message3.title': "Klinkt logisch. Ik zet de beelden even achter elkaar.",

                    'translate.who_did_it_hard.player.hint.explanation': "There are six videos that need to be placed in the right order. Focus on the doormat and and the coffee cups. What happens at the start and what at the end of each video?",
                    'translate.who_did_it_hard.player.hint.hint1': "Start with the video where there are no coffee cups at the start. Let one player focus on the cups and the other on the doormat.",
                    'translate.who_did_it_hard.player.hint.hint2': "The first video ends with 1 cup and no doormat. The second should start with 1 cup and no doormat, and ends with 2 cups and a doormat. The third starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
                    'translate.who_did_it_hard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.who_did_it_hard.guest.hint.explanation': "There are six videos that need to be placed in the right order. Focus on the doormat and and the coffee cups. What happens at the start and what at the end of each video?",
                    'translate.who_did_it_hard.guest.hint.hint1': "Start with the video where there are no coffee cups at the start. Let one player focus on the cups and the other on the doormat.",
                    'translate.who_did_it_hard.guest.hint.hint2': "The first video ends with 1 cup and no doormat. The second should start with 1 cup and no doormat, and ends with 2 cups and a doormat. The third starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
                    'translate.who_did_it_hard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.who_did_it_hard.message1.title': "There was a camera in the corner. Still works with disks. I have found some recordings.",
                    'translate.who_did_it_hard.message2.title': "If we know the right order of the recordings, maybe we can figure out who poisoned John.",
                    'translate.who_did_it_hard.message2.error': "This isn't the correct order, I see some changes between recordings I cannot explain. Can you check again?",
                    'translate.who_did_it_hard.message3.title': "That woman is the last one...."
                },
                tagOptionsDialog: {
                    availableOptions: "Beschikbare opties ({{count}})",
                    submitButton: "Verzenden",
                    clearBtn: "Leeg",
                    selected: "Geselecteerd",
                    inOrder: "op volgorde",
                },
                taggerCarousel: {
                    altText: "afbeelding taggen",
                    confirmButton: "Bevestigen",
                    submitButtonText: "Verzenden",
                    tagAllImages: "Tag alle afbeeldingen"
                },
                resultsDialog: {
                    resultsTitle: "Resultaten",
                    resultsScoreDifficultyCap: "Jullie zijn te slim voor dit niveau! We hebben de moeilijkheidsgraad verhoogd naar uitdagend. Jullie krijgen andere informatie, meer punten en geen hulp meer van Steve... Wil je weer terug naar Standaard? Dan kan speler 1 tijdens de opdracht de moeilijkheid aanpassen in het menu.",
                    hintsUsed: "Totaal gebruikte hints",
                    incorrectScore: "Totaal incorrecte score",
                    solutionsTried: "Totaal geprobeerde oplossingen",
                    timeSpent: "Besteedde tijd",
                    totalPlayerHintsUsed: "Totaal door speler gebruikte hints",
                    totalGuestHintsUsed: "Totaal door gast gebruikte hints",
                    totalPlayerSolutionsTried: "Totaal aantal onjuiste pogingen",
                    grandTotalResults: "Resultaat alle puzzels",
                    totalScore: "Score",
                    congratulationsText: "Gefeliciteerd! Jullie hebben de zaak opgelost!",
                    waitMessage: "Wacht tot de andere speler op resultaten drukt en probeer opnieuw!",
                    goToMenu: "Ga naar Menu",
                    continueButton: "Doorgaan",
                    curiousFriends: "Wil je de score van jullie vrienden weten?",
                    shareWithFriends: "Nodig ze uit en vergelijk jullie scores!",
                    shareMessage: "{{playerName}} en {{partnerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald voor {{puzzleName}} in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}",
                    shareGrandTotalMessage: "{{playerName}} en {{partnerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}"
                },
                continuePuzzleVideo: {
                    'Intro 1': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - Intro 2 - en druk tegelijk op Doorgaan.',
                    'Intro 2': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Deceased persons': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 3. Password - en druk tegelijk op Doorgaan.',
                    'Differences': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Drone': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 5. Poison - en druk tegelijk op Doorgaan.',
                    'Enclose': 'Gefeliciteerd! Jullie hebben het samen opgelost. Klik op Doorgaan om je totaalscore te bekijken en jullie vrienden uit te dagen!',
                    'Escape': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Open the door': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 2. Deceased persons - en druk tegelijk op Doorgaan.',
                    'Password': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 4. Drone - en druk tegelijk op Doorgaan.',
                    'Poison': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 6. Who did it? - en druk tegelijk op Doorgaan.',
                    'Tools and wires': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Who did it': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 7. Enclose - en druk tegelijk op Doorgaan.',
                },
                puzzleCard: {
                    difficultyPlayed: "Gespeelde moeilijkheidsgraad:",
                    hintsUsedByPlayer: "Aantal door speler gebruikte hints:",
                    hintsUsedByGuest: "Aantal door gast gebruikte hints:",
                    solutionsTriedByPlayer: "Aantal door speler geprobeerde oplossingen:",
                    solutionsTriedByGuest: "Aantal door gast geprobeerde oplossingen:",
                    expires: "Verloopt",
                    seeStats: "Bekijk statistieken"
                },
                problemModal: {
                    sorry: "Het spijt ons van de bug! Maar geen zorgen, we zijn hier om het weer op het juiste spoor te brengen!",
                    clickContinue: "Door op doorgaan te klikken, start je de puzzel opnieuw op.",
                    areYouSure: "Weet je het zeker?",
                    reset: "Reset",
                    guestNeedRejoin: "De gast moet opnieuw worden uitgenodigd. Deel de link met de gastspeler.",
                    inviteContinue: "Uitgenodigd, ga door!",
                    doneOrSupport: "Dat is het! Refresh deze pagina en het spel gaat door. Neem contact op met de klantenservice als dit probleem aanhoudt.",
                    reloadPage: "Refresh pagina",
                    problem: "Probleem?",
                },
                instructionsModal: {
                    instruction1: "Hoe werkt het spel?<br>" +
                        "<br>1. A Toxic Case is een spel voor 2 spelers en gaat over een vermissingszaak. Om het spel te spelen heeft iedere speler zijn eigen smartphone nodig." +
                        "<br>2. Beide spelers krijgen steeds dezelfde opdracht, maar ontvangen verschillende informatie.  " +
                        "<br>3. Om de opdracht uit te voeren, kijken jullie samen naar dezelfde video. Jullie zullen de informatie die je hebt ontvangen moeten bespreken en samen moeten werken." +
                        "<br>4. De videos kun je bekijken via Vimeo of het CouchClues Youtube kanaal. We raden aan om het op je TV te bekijken of te streamen, maar je kan elk scherm gebruiken wat je wil." +
                        "<br>5. Iedere video start met een deel van het verhaal en daarna beelden die betrekking hebben op je opdracht. De beelden die je nodig hebt zullen steeds worden herhaald, zodat je een nieuwe kans hebt de zaak op te lossen.",
                    instruction2: "Hints en scores<br>" +
                        "<br>1. Zodra je een opdracht hebt gekregen start er een timer. Je kunt proberen de opdrachten zo snel mogelijk uit te voeren om een hoge score te halen, maar je kunt dit ook negeren en het rustig op je eigen tijd doen." +
                        "<br>2. Als het niet lukt om de opdracht uit te voeren, kun je in het menu rechtsboven een hint gebruiken. De uitleg helpt als je geen idee hebt hoe je moet beginnen en de andere hints zijn er voor als je niet verder komt." +
                        "<br>3. Beide spelers kunnen dezelfde hint bekijken zonder dat dit extra punten kost." +
                        "<br>4. Kom je er met de hints niet uit, dan kun je op Oplossing klikken om de oplossing en de uitleg te zien.",
                    instruction3: "Hoe start je het spel?<br>" +
                        "<br>1. De speler die het spel heeft gekocht ontvangt een link. Met de link kan Speler 1 het spel opstarten." +
                        "<br>2. Als speler 1 het spel opstart krijgt deze een nieuwe link, waarmee een gast speler kan worden uitgenodigd." +
                        "<br>3. Het spel start met twee intro opdrachten om het spel te leren kennen." +
                        "<br>3. Als je een bug of een ander probleem ervaart, kan Speler 1 op de knop Problemen klikken om de huidige opdracht opnieuw op te starten.",
                    btnText: 'Begrepen!'
                },
                navbar: {
                    inviteFriend: "Nodig een vriend uit",
                    instructions: "Instructies",
                    instructionsUrl: "https://couchclues.com/instructions/",
                    problem: "Problemen?",
                    quit: "Stoppen",
                },
                difficultyModal: {
                    areYouSure: "Weet je zeker dat je de moeilijkheidsgraad wilt veranderen naar",
                    dialogContent: "Door de moeilijkheidsgraad te veranderen, start je deze puzzel opnieuw. \n Je gastspeler moet de pagina refreshen om verder te gaan. Weet je zeker dat je wilt doorgaan?",
                    cancel: "Annuleren",
                    confirm: "Bevestigen",
                }
            }

        },
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["game", "tagOptionsDialog", "taggerCarousel", "resultsDialog", "puzzleCard", "problemModal", "navbar", "difficultyModal"],
        defaultNS: "game",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
